import { isClient } from '@vueuse/core';

export default function useImageLoadError(path: string) {
  if (!isClient) {
    return { hasImageError: false, updatePath: (_: string) => '' };
  }

  const image = new Image();
  image.src = path;

  const hasImageError = ref(false);

  image.addEventListener('error', () => {
    hasImageError.value = true;
  });

  const updatePath = (path: string) => {
    hasImageError.value = false;

    image.src = path;
  };

  return { hasImageError, updatePath };
}
