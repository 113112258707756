import type { CollectionContentType, CollectionItem } from '@package/sdk/src/api';

import { useUserCollectionApi } from '../user/use-user-collection-api';
import type { RemoteCollections } from './collections';

export function useRemoteCollections(): RemoteCollections {
  const userCollectionApi = useUserCollectionApi();

  const get = (contentType: CollectionContentType, page: number, limit: number): Promise<CollectionItem[]> => {
    return userCollectionApi.getItems(contentType, page, limit).catch(() => [] as CollectionItem[]);
  };

  const add = (items: CollectionItem[]) =>
    userCollectionApi
      .addItems(items.map((item: CollectionItem) => item.id))
      .then(() => true)
      .catch(() => false);

  const remove = (id: string) =>
    userCollectionApi
      .deleteItem(id)
      .then(() => true)
      .catch(() => false);

  return {
    context: { add, remove, get },
  };
}
