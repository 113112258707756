import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import type { AnyFunction } from '@package/sdk/src/core/structures/common';
import { isClient } from '@vueuse/core';

import { logger } from '@/platform/logger/use-logger';

interface YandexMetricaInitializeOptions {
  id?: string;
  isDev: boolean;
}

export type YandexMetricaParams = Record<string, any>;

export type GetYandexMetricaIDCallback = (clientId: string) => void;

export type YandexMetricaFunction = (
  id: string,
  goal: 'reachGoal' | 'params' | 'getClientID' | 'setUserID',
  event: AnalyticEventNameNew | AnyFunction | string,
  params?: YandexMetricaParams,
) => void;

declare global {
  interface Window {
    ym?: YandexMetricaFunction;
  }
}

function ym(ymId: string, eventName: AnalyticEventNameNew, params?: YandexMetricaParams) {
  if (isClient && window.ym) {
    if (params) {
      window.ym(ymId, 'params', eventName, params);
    }

    window.ym(ymId, 'reachGoal', eventName);
  }
}

function requestYandexMetricaClientID(ymId: string, callback: GetYandexMetricaIDCallback) {
  if (isClient && window.ym) {
    window.ym(ymId, 'getClientID', callback);
  }
}

function requestYandexMetricaSetUserID(ymId: string, userId: string) {
  if (isClient && window.ym) {
    window.ym(ymId, 'setUserID', userId);
  }
}

export class YandexEventSender {
  private yandexMetricaId?: string;
  private isDev: boolean = false;

  constructor() {}

  public setYandexMetricaID = (callback: GetYandexMetricaIDCallback) => {
    if (!this.yandexMetricaId || this.isDev) {
      return;
    }

    try {
      requestYandexMetricaClientID(this.yandexMetricaId, callback);
    } catch (error) {
      logger.error(error);
    }
  };

  public setYandexMetricaUserID = (userId: string) => {
    if (!this.yandexMetricaId || this.isDev) {
      return;
    }

    try {
      requestYandexMetricaSetUserID(this.yandexMetricaId, userId);
    } catch (error) {
      logger.error(error);
    }
  };

  public sendYmEvent = (eventName: AnalyticEventNameNew) => {
    if (!this.yandexMetricaId || this.isDev) {
      return;
    }

    try {
      ym(this.yandexMetricaId, eventName);
    } catch (error) {
      logger.error(error);
    }
  };

  public sendYmEventWithParams = (eventName: AnalyticEventNameNew, params: YandexMetricaParams) => {
    if (!this.yandexMetricaId || this.isDev) {
      return;
    }

    try {
      ym(this.yandexMetricaId, eventName, params);
    } catch (error) {
      logger.error(error);
    }
  };

  public initialize = (options: YandexMetricaInitializeOptions) => {
    this.yandexMetricaId = options.id;
    this.isDev = options.isDev;
  };
}
