<template>
  <app-modal-wrapper
    :max-width="480"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.QuizModal, 'modalWrapper')"
    :class="{
      [$style.quizModal]: true,
      [$style.error]: step === Step.Error,
    }"
  >
    <template v-if="step !== Step.Error">
      <modal-title :title="$t('quizModal.title')" />
      <modal-description :description="subtitle" />
    </template>

    <div
      :class="{
        [$style.itemsWrapper]: true,
        [$style.itemsWrapperHeight]: isContentsLoading,
      }"
    >
      <app-loader v-show="isContentsLoading" />

      <div v-show="!isContentsLoading" :class="$style.items">
        <template v-if="step === Step.Genres">
          <app-slot-button
            v-for="genre in genres"
            :key="genre.id"
            :title="$t('ariaLabel.quizModal.genre.button', { genre: genre.title })"
            :aria-label="$t('ariaLabel.quizModal.genre.button', { genre: genre.title })"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.QuizModal, 'genreItem')"
            :class="{
              [$style.genre]: true,
              [$style.active]: isGenreSelected(genre),
            }"
            @click="onGenreClick(genre)"
          >
            <div :style="`--active-background: ${genre.color}`" :class="$style.genreTile">
              <img :src="genre.image" :class="$style.genreImage" :alt="genre.title" />
            </div>

            <p :class="$style.genreTitle">{{ genre.title }}</p>
          </app-slot-button>
        </template>

        <template v-else-if="step === Step.Contents">
          <app-slot-button
            v-for="content in contents"
            :key="content.id"
            :title="$t('ariaLabel.quizModal.content.button', { content: content.title })"
            :aria-label="$t('ariaLabel.quizModal.content.button', { content: content.title })"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.QuizModal, 'contentItem')"
            :class="{
              [$style.content]: true,
              [$style.active]: isContentSelected(content),
            }"
            @click="onContentClick(content)"
          >
            <div :class="$style.contentTile">
              <app-img
                :alt="content.title"
                :loading-lazy="false"
                :width="150"
                :src="content.poster"
                :image-class="$style.contentImage"
                @load="contentPosterLoaded"
              />

              <div :class="$style.contentOverlay">
                <icon-heart size="large" :filled="true" />
              </div>
            </div>
          </app-slot-button>
        </template>

        <template v-else>
          <icon-clear size="large" :class="$style.errorIcon" />
          <p :class="$style.errorText">
            {{ $t('quizModal.errorLoad') }}
          </p>
          <app-button :text="buttonText" @click="onButtonClick" />
        </template>
      </div>
    </div>

    <div :class="$style.buttons">
      <app-button
        v-if="step !== Step.Error"
        :class="$style.buttonNext"
        :text="buttonText"
        :aria-label="$t('ariaLabel.quizModal.next')"
        :title="$t('ariaLabel.quizModal.next')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.QuizModal, 'nextButton')"
        @click="onButtonClick"
      />

      <app-button
        v-if="step === Step.Contents"
        variation="link-primary"
        :class="$style.buttonBack"
        :aria-label="$t('ariaLabel.quizModal.back')"
        :title="$t('ariaLabel.quizModal.back')"
        :text="$t('common.back')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.QuizModal, 'backButton')"
        @click="onBackButtonClick"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import type { QuizContentItem, QuizGenreItem } from '@package/sdk/src/api';
import { computed, onMounted, ref } from 'vue';

import { useQuizApi } from '@/code/quiz/use-quiz-api';
import { useLogger } from '@/platform/logger/use-logger';
import { useLayoutStore } from '@/stores/use-layout-store';

import useLocale from '../../platform/localization/use-locale';
import AppLoader from '../app-loader/AppLoader.vue';
import AppModalWrapper from '../app-modal/AppModalWrapper.vue';
import IconClear from '../icons/IconClear.vue';
import IconHeart from '../icons/IconHeart.vue';
import AppButton from '../ui/AppButton.vue';
import AppImg from '../ui/AppImg.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import ModalDescription from './ModalDescription.vue';
import ModalTitle from './ModalTitle.vue';

enum Step {
  Genres = 'genres',
  Contents = 'contents',
  Error = 'error',
}

const quizApi = useQuizApi();
const logger = useLogger();
const { translate } = useLocale();

const step = ref<Step>(Step.Genres);
const prevStep = ref<Step>(Step.Genres);
const isContentsLoading = ref(false);
const loadedContentsNumber = ref(0);

const genres = ref<QuizGenreItem[]>([]);
const selectedGenres = ref<QuizGenreItem[]>([]);
const selectedGenresIds = ref<string[]>([]);

const contents = ref<QuizContentItem[]>([]);
const selectedContents = ref<QuizContentItem[]>([]);
const selectedContentsIds = ref<string[]>([]);

const layoutStore = useLayoutStore();

const buttonText = computed(() => {
  switch (step.value) {
    case Step.Genres:
      return translate('quizModal.button.genres');
    case Step.Contents:
      return translate('quizModal.button.contents');
    default:
      return translate('quizModal.button.error');
  }
});

const subtitle = computed(() =>
  step.value === Step.Genres ? translate('quizModal.subtitle.genres') : translate('quizModal.subtitle.contents'),
);

const setSelectedContentsIds = () => {
  selectedContentsIds.value = selectedContents.value.map((item) => item.id);
};

const setSelectedGenresIds = () => {
  selectedGenresIds.value = selectedGenres.value.map((item) => item.id);
};

const fetchGenresContents = async () => {
  try {
    contents.value = await quizApi.fetchGenresContents({
      quiz_genres_ids: selectedGenres.value.map((item) => item.id),
    });

    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

const closeModal = () => {
  setSelectedContentsIds();
  setSelectedGenresIds();

  layoutStore.setCurrentModalName(null);
};

const fetchGenres = async () => {
  try {
    genres.value = await quizApi.fetchGenres();
    return true;
  } catch (error) {
    step.value = Step.Error;
    logger.error(error);
    return false;
  }
};

const handleGenresContentsRequest = (success: boolean) => {
  if (success) {
    step.value = Step.Contents;
  } else {
    prevStep.value = Step.Contents;
    step.value = Step.Error;
  }
  isContentsLoading.value = false;
};

const loadGenresContents = async () => {
  isContentsLoading.value = true;
  selectedContents.value = [];
  setSelectedGenresIds();
  const success = await fetchGenresContents();
  handleGenresContentsRequest(success);
};

const onErrorButtonClick = async () => {
  let success: boolean;
  isContentsLoading.value = true;

  if (prevStep.value === Step.Genres) {
    success = await fetchGenres();
  } else {
    selectedContents.value = [];
    setSelectedGenresIds();
    success = await fetchGenresContents();
  }
  isContentsLoading.value = false;

  if (!success) {
    return;
  }

  step.value = prevStep.value;
};

const onButtonClick = async () => {
  if (step.value === Step.Genres) {
    await loadGenresContents();
  } else if (step.value === Step.Contents) {
    closeModal();
  }

  if (step.value === Step.Error) {
    await onErrorButtonClick();
  }
};

const isGenreSelected = (genre: QuizGenreItem) => selectedGenres.value.find((item) => item.id === genre.id);

const isContentSelected = (content: QuizContentItem) => selectedContents.value.find((item) => item.id === content.id);

const onGenreClick = (genre: QuizGenreItem) => {
  if (isGenreSelected(genre)) {
    selectedGenres.value = selectedGenres.value.filter((item) => item.id !== genre.id);
  } else {
    selectedGenres.value.push(genre);
  }
};

const onContentClick = (content: QuizContentItem) => {
  if (isContentSelected(content)) {
    selectedContents.value = selectedContents.value.filter((item) => item.id !== content.id);
  } else {
    selectedContents.value.push(content);
  }
};

const contentPosterLoaded = () => {
  loadedContentsNumber.value++;

  if (loadedContentsNumber.value !== contents.value.length) {
    return;
  }

  isContentsLoading.value = false;
  loadedContentsNumber.value = 0;
};

onMounted(fetchGenres);

const onBackButtonClick = () => {
  step.value = Step.Genres;
  prevStep.value = Step.Genres;
  selectedContents.value = [];
  selectedContentsIds.value = [];
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/aspect-ratio' as aspect-ratio;
@use '../../assets/breakpoints' as breakpoints;

.quizModal {
  display: flex;
  flex-direction: column;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 100%;
  gap: 10px;
}

.itemsWrapper {
  margin: var(--g-spacing-32) 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.itemsWrapperHeight {
  height: 420px;
}

.genre {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
  flex-direction: column;

  &:focus {
    outline: 0;
  }

  &.active {
    .genreTile {
      border-color: var(--active-background);
      background-color: var(--active-background);
    }
  }
}

.genreTile {
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: var(--g-border-round-24);
  border: 1px solid transparent;
  background-color: var(--color-bg-tertiary);
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.genreImage {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50px;
  transform: translate(-50%, -50%);
}

.genreTitle {
  @include fonts.WebCaption-1;
  text-align: center;
  margin-top: 3px;
}

.content {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &.active {
    .contentOverlay {
      opacity: 1;
    }
  }
}

.contentImage {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.contentTile {
  width: 100%;
  height: 100%;
  user-select: none;

  img {
    max-width: 100%;
  }
}

.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: var(--color-states-bg-accent-50);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--g-spacing-8);
}

.quizModal.error {
  .errorIcon {
    margin-bottom: 14px;
  }

  .errorText {
    @include fonts.WebLabel-2;
    max-width: 174px;
    color: var(--color-text-tertiary);
    text-align: center;
    margin-bottom: 50px;
  }

  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@include breakpoints.max-width-800 {
  .itemsWrapper {
    overflow: scroll;
  }

  .genre {
    width: 100%;
    height: 100%;
  }

  .genreTile {
    @include aspect-ratio.ratio(1, 1);
    width: 100%;
    height: 100%;
  }

  .genreTitle {
    @include fonts.WebLabel-3;
  }

  .buttonBack {
    flex: 0.5;
  }

  .buttonNext {
    flex: 1;
  }
}

@include breakpoints.max-width-480 {
  .items {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@include breakpoints.max-width-375 {
  .items {
    gap: 5px;
  }
}
</style>
