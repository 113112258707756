import { LikeState } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';

import { useLogger } from '@/platform/logger/use-logger';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLocalStorage } from '@/platform/storage/use-local-storage';
import { useSessionStore } from '@/stores/use-session-store';

import { useLikesApi } from '../likes/use-likes-api';
import type { LocalLikes } from './likes';

export function useLocalLikes(): LocalLikes {
  const { isAuth } = storeToRefs(useSessionStore());
  const likesApi = useLikesApi();
  const logger = useLogger();
  const localStorage = useLocalStorage();

  const getStorageData = (likeState: LikeState): string[] => {
    if (likeState === LikeState.Dislike) {
      const dislikes = localStorage.getValue(LocalStorageKey.MyChannelDislikes)?.value as string[];

      if (dislikes) {
        return dislikes;
      }
    }

    const likes = localStorage.getValue(LocalStorageKey.MyChannelLikes)?.value as string[];

    if (likes) {
      return likes;
    }

    return [];
  };

  const hasLikeInStorage = (id: string) => {
    return Boolean(getStorageData(LikeState.Like).find((itemId) => itemId === id));
  };

  const hasDislikeInStorage = (id: string) => {
    return Boolean(getStorageData(LikeState.Dislike).find((itemId) => itemId === id));
  };

  const getLocalLikesLength = () => {
    const likesLength = getStorageData(LikeState.Like).length;
    const dislikesLength = getStorageData(LikeState.Dislike).length;

    return likesLength + dislikesLength;
  };

  const clearLikes = () => {
    localStorage.remove(LocalStorageKey.MyChannelLikes);
  };

  const clearDislikes = () => {
    localStorage.remove(LocalStorageKey.MyChannelDislikes);
  };

  const addLike = (id: string) => {
    const storageData = getStorageData(LikeState.Like);
    storageData.push(id);
    localStorage.setValue(LocalStorageKey.MyChannelLikes, { value: storageData });

    removeDislike(id);

    return true;
  };

  const addDislike = (id: string) => {
    const storageData = getStorageData(LikeState.Dislike);
    storageData.push(id);
    localStorage.setValue(LocalStorageKey.MyChannelDislikes, { value: storageData });

    removeLike(id);
    return true;
  };

  const removeLike = (id: string) => {
    const filtered = getStorageData(LikeState.Like).filter((itemId) => itemId !== id);
    localStorage.setValue(LocalStorageKey.MyChannelLikes, { value: filtered });

    return true;
  };

  const removeDislike = (id: string) => {
    const filtered = getStorageData(LikeState.Dislike).filter((itemId) => itemId !== id);
    localStorage.setValue(LocalStorageKey.MyChannelDislikes, { value: filtered });

    return true;
  };

  const uploadLocalLikes = async () => {
    if (!isAuth.value) {
      return;
    }

    const likes = getStorageData(LikeState.Like);
    const dislikes = getStorageData(LikeState.Dislike);

    try {
      await likesApi.postLikes({ like: likes || [], dislike: dislikes || [] });

      clearLikes();
      clearDislikes();
    } catch (error) {
      logger.error(error);
    }
  };

  return {
    context: { addLike, addDislike, removeLike, removeDislike },
    getStorageData,
    hasLikeInStorage,
    hasDislikeInStorage,
    getLocalLikesLength,
    clearLikes,
    clearDislikes,
    uploadLocalLikes,
  };
}
