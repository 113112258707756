import type { Offer, Subscription } from '@package/sdk/src/api';
import { SubscriptionPlatform, SubscriptionState } from '@package/sdk/src/api';
import { isAfter } from 'date-fns';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { useReferralApi } from '@/code/bonus-programs/use-referral-api';
import { useOffersApi } from '@/code/offer/use-offers-api';
import { useLogger } from '@/platform/logger/use-logger';

import { useSessionStore } from './use-session-store';

export const useOffersStore = defineStore('offers', () => {
  const sessionStore = useSessionStore();
  const offersApi = useOffersApi();
  const referralApi = useReferralApi();
  const logger = useLogger();

  const { user } = storeToRefs(sessionStore);

  const _offers = ref<Offer[]>([]);
  const _offer = ref<Offer>({} as Offer);
  const currentOffer = computed(() => _offer.value);

  const offers = computed(() => _offers.value);

  const getByPlatform = (platform: SubscriptionPlatform) =>
    _offers.value.find((offer: Offer) => offer.platform === platform);

  const hasOfferTrial = computed(() => !!_offer.value?.trialAvailable);
  const currentOfferPrice = computed(() => _offer.value?.price);
  const subscription = computed(() => user.value?.subscription as Subscription);

  const currentUserPlatform = computed(() => subscription.value?.platform as SubscriptionPlatform);

  const currentSystemPlatform = computed(() => SubscriptionPlatform.Cloudpayments);

  const isSubscriptionTrial = computed(() => subscription.value?.state === SubscriptionState.Trial);
  const isSubscriptionNormal = computed(() => subscription.value?.state === SubscriptionState.NormalPeriod);
  const isSubscriptionGrace = computed(() => subscription.value?.state === SubscriptionState.GracePeriod);

  const isInactivePartnerSubscription = computed(() => isPartnerSubscription.value && !isActiveSubscription.value);

  const isActiveSubscription = computed(() => Boolean(subscription.value?.active));
  const isSubscriptionExists = computed(() => Boolean(subscription.value));
  const isInitialSubscription = computed(() => subscription.value?.state === SubscriptionState.Initial);
  const isPartnerSubscription = computed(() => currentUserPlatform.value === SubscriptionPlatform.Partner);
  const isCanceledSubscription = computed(() => subscription.value?.state === SubscriptionState.Canceled);
  const isSubscriptionRecurrent = computed(() => Boolean(subscription.value?.recurrent));

  const isFreeEpisodeAvailableForUser = computed(() => !isPartnerSubscription.value && !isActiveSubscription.value);

  const canManageSubscription = computed(() => !!user.value?.allowedManageSubscription);

  const isSubscriptionWithoutLinkingCard = computed(() => !canManageSubscription.value && isActiveSubscription.value);

  const isSubscriptionCloudpayments = computed(() => currentUserPlatform.value === SubscriptionPlatform.Cloudpayments);
  const isSubscriptionApple = computed(() => currentUserPlatform.value === SubscriptionPlatform.Apple);
  const isSubscriptionGoogle = computed(() => currentUserPlatform.value === SubscriptionPlatform.Google);
  const isSubscriptionPayture = computed(() => currentUserPlatform.value === SubscriptionPlatform.Payture);
  const isSubscriptionSber = computed(() => currentUserPlatform.value === SubscriptionPlatform.Sber);

  const isManagedPaymentSubscription = computed(
    () => isSubscriptionSber.value || isSubscriptionPayture.value || isSubscriptionCloudpayments.value,
  );

  const isExternalSubscription = computed(
    () =>
      isSubscriptionApple.value ||
      isSubscriptionGoogle.value ||
      isSubscriptionSber.value ||
      isPartnerSubscription.value,
  );

  const hasLinkedCard = computed(() => Boolean(user.value?.paymentMethods.length));

  const isSubscriptionOutdated = computed(() => isAfter(new Date(), new Date(subscription.value?.endsAt)));

  const getOffers = async (bonusId?: string): Promise<Offer[]> => {
    try {
      _offers.value = await offersApi.getAll(bonusId);
    } catch (e) {
      logger.error(e);
      _offers.value = [];
    }

    return _offers.value;
  };

  const loadOffer = async () => {
    await getOffers();
    const offer = getByPlatform(currentUserPlatform.value) || getByPlatform(SubscriptionPlatform.Payture);

    if (!offer) {
      return logger.error('Expect offer to be defined');
    }

    _offer.value = offer;
  };

  const fetchReferralProgram = async () => {
    const referralPrograms = await referralApi.getReferralPrograms();
    return referralPrograms;
  };

  return {
    hasOfferTrial,
    isSubscriptionTrial,
    isSubscriptionNormal,
    isSubscriptionGrace,
    currentOfferPrice,
    isActiveSubscription,
    hasLinkedCard,
    isInactivePartnerSubscription,
    isSubscriptionWithoutLinkingCard,
    isSubscriptionCloudpayments,
    isSubscriptionSber,
    isSubscriptionApple,
    isSubscriptionGoogle,
    isSubscriptionExists,
    isInitialSubscription,
    isSubscriptionOutdated,
    isCanceledSubscription,
    isSubscriptionRecurrent,
    isPartnerSubscription,
    isExternalSubscription,
    isManagedPaymentSubscription,
    isFreeEpisodeAvailableForUser,
    currentOffer,
    _offers,
    _offer,
    offers,
    currentUserPlatform,
    currentSystemPlatform,
    subscription,
    fetchReferralProgram,
    getOffers,
    loadOffer,
  };
});
