import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { AppPlayerLanguage } from '@PLAYER/player/modules/localization/translate';
import type { Locale } from 'date-fns';
import { format as formatFunc, formatDuration, intervalToDuration } from 'date-fns';
import arMA from 'date-fns/locale/ar-MA/index';
import enGB from 'date-fns/locale/en-GB/index';
import ru from 'date-fns/locale/ru/index';

const DateFormat = {
  [AppPlayerLanguage.EN]: 'dd.MM.yyyy',
  [AppPlayerLanguage.RU]: 'dd.MM.yyyy',
  [AppPlayerLanguage.AM]: 'dd.MM.yyyy',
};

const zeroPad = (num: number) => String(num).padStart(2, '0');

export const AppDateManager = new (class {
  private locale: AppPlayerLanguage = AppPlayerLanguage.RU;

  public setLocale(locale: AppPlayerLanguage) {
    this.locale = locale;
  }

  public formatDuration = (seconds: number) => {
    return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
      format: ['hours', 'minutes', 'seconds'],
      zero: true,
      delimiter: ':',
    });
  };

  public format = (date: Date | number): string => {
    const format = this.getFormat();
    const locale = this.getLocale();

    return formatFunc(date, format, {
      locale,
    });
  };

  public formatDateWithCustomFormat = (date: Date | number, format: string): string => {
    const locale = this.getLocale();

    return formatFunc(date, format, {
      locale,
    });
  };

  private getFormat() {
    return DateFormat[this.locale] || ConstantsConfigInstanceWeb.getProperty('defaultDateFormat');
  }

  private getLocale(): Locale {
    if (this.locale === AppPlayerLanguage.RU) {
      return ru;
    }

    if (this.locale === AppPlayerLanguage.AM) {
      return arMA;
    }

    if (this.locale === AppPlayerLanguage.EN) {
      return enGB;
    }

    return ru;
  }
})();
