import type { ApiPlaylists, Playlist } from '@package/sdk/src/api';
import { PlaylistsMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function usePlaylistsApi() {
  const { $http } = useNuxtApp();

  const fetchPlaylists = (): Promise<Playlist[]> => {
    return $http.get<ApiPlaylists>(ENDPOINTS.PLAYLISTS).then(PlaylistsMapper.mapMany);
  };

  return { fetchPlaylists };
}
