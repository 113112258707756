import { ApplicationError, isArray, isObject } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';

interface IApiErrorData {
  title?: string;
  message?: string;
  error: any;
}

export interface ApiErrorAsyncData {
  url: string;
  status: HTTPStatusCode;
  statusCode: HTTPStatusCode;
  data: IApiErrorData;
}

export class ApiError extends ApplicationError {
  public readonly name = 'ApiError';

  constructor(
    public readonly url: string,
    public readonly status: HTTPStatusCode,
    public readonly data: IApiErrorData,
  ) {
    super();
  }

  public is(status: HTTPStatusCode): boolean {
    return this.status === status;
  }

  public toJSON(): Record<string, any> {
    const { url, status } = this;

    return {
      url,
      status,
    };
  }

  public get message(): string {
    if (isObject(this.data.error)) {
      const values = Object.values(this.data.error);

      if (isArray(values[0])) {
        return values[0][0] as string;
      }

      return values[0];
    }

    // TODO: LOCALE
    return this.data?.message || 'Неизвестная ошибка';
  }
}
