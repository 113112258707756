<template>
  <app-modal-wrapper>
    <enter-pin-code-modal-prompt @success="onSuccessPinCode" @unsuccessful="onUnsuccessfulPinCode" />
  </app-modal-wrapper>
</template>
<script setup lang="ts">
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import type { Profile } from '@package/sdk/src/api';

import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

import useProfile from '../../code/profile/use-profile';
import EnterPinCodeModalPrompt from '../parental-control/EnterPinCodeModalPrompt.vue';

const layoutStore = useLayoutStore();
const sessionStore = useSessionStore();
const { onAutoParentalCodeInvalid } = useParentalControlAnalytics();

const { mainProfile } = useProfile();

const onUnsuccessfulPinCode = () => {
  onAutoParentalCodeInvalid();
};

const onSuccessPinCode = async () => {
  await sessionStore.setProfile(mainProfile.value as Profile);
  await sessionStore.refreshSession();
  layoutStore.setCurrentModalName(null);
};
</script>
