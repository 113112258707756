import type {
  ApiCloudPaymentsInfoResponse,
  ApiCloudPaymentsInvoiceRequest,
  ApiCloudPaymentsInvoiceResponse,
  CloudPaymentsInvoice,
} from '@package/sdk/src/api';
import { CloudpaymentsInvoiceMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useCloudPaymentsApi() {
  const { $http } = useNuxtApp();

  const getPaymentInfo = (): Promise<ApiCloudPaymentsInfoResponse> =>
    $http.get(ENDPOINTS.SUBSCRIPTION_CLOUDPAYMENTS_INFO);

  const getInvoice = (body: Partial<ApiCloudPaymentsInvoiceRequest>): Promise<CloudPaymentsInvoice> => {
    return $http
      .post<ApiCloudPaymentsInvoiceResponse>(ENDPOINTS.SUBSCRIPTION_CLOUDPAYMENTS, body)
      .then(CloudpaymentsInvoiceMapper.map);
  };

  const addPaymentCard = (): Promise<CloudPaymentsInvoice> => {
    return $http
      .post<ApiCloudPaymentsInvoiceResponse>(ENDPOINTS.SUBSCRIPTION_CLOUDPAYMENTS_ADD_CARD, {})
      .then(CloudpaymentsInvoiceMapper.map);
  };

  return { getPaymentInfo, getInvoice, addPaymentCard };
}
