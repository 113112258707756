import type { ApiYear } from '@package/sdk/src/api';
import { YearMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';
import { useLogger } from '@/platform/logger/use-logger';

export function useYearsApi() {
  const { $http } = useNuxtApp();
  const logger = useLogger();

  const fetchYears = async () => {
    try {
      const data = await $http.get<ApiYear[]>(ENDPOINTS.YEARS).then(YearMapper.mapMany);
      return data;
    } catch (e) {
      logger.error(e);
      return [];
    }
  };

  return { fetchYears };
}
