import { isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';

export default abstract class AbstractConstantsConfig<T> {
  private readonly map: T;

  protected constructor(constantsMap: T) {
    this.map = constantsMap;
  }

  public getProperty<K extends keyof T>(key: K): T[K] {
    if (isUndefinedOrNull(this.map[key])) {
      throw new UnexpectedComponentStateError(key as string);
    }

    return this.map[key];
  }
}
