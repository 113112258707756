<template>
  <div
    v-show="isSearchAvailable"
    :class="{
      [$style.searchWidget]: true,
      [$style.searchWidgetOpened]: isSearchOpen,
    }"
  >
    <app-slot-button
      v-if="!isSearchOpen"
      :title="$t('ariaLabel.header.openSearchButton')"
      :aria-label="$t('ariaLabel.header.openSearchButton')"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'openSearchButton')"
      :class="$style.searchButton"
      @click="onSearchButtonClick"
    >
      <icon-search size="small" :filled="false" />
    </app-slot-button>

    <transition name="fade">
      <div v-if="isSearchOpen" :class="$style.searchWrapper">
        <div
          ref="searchInputWrapperRef"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Search, 'inputWrapper')"
          :class="$style.searchInputWrapper"
        >
          <app-input
            ref="searchInputEl"
            v-model="searchQueryText"
            :class="$style.searchInput"
            :placeholder="getRandomPlaceholder()"
            :spellcheck="false"
            variation="secondary"
            autocomplete="off"
            size="small"
            :has-clear-icon="searchQueryText.length > 0"
            @clear="onClearInputClick"
            @input="onInput"
            @keydown.enter="onEnter"
            @keydown.down.prevent="onKeydownArrowDown"
            @keydown.up.prevent="onKeydownArrowUp"
          >
            <template #leadingIcon>
              <icon-search size="small" :filled="false" />
            </template>
          </app-input>
        </div>

        <div
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Search, 'contentWrapper')"
          :class="$style.contentWrapper"
        >
          <div v-if="isSearchResultDropdownShow">
            <div v-for="(option, index) in searchData" ref="contentLinksListRef" :key="index">
              <small v-if="isFirstMovieContentItem(option, index)" :class="$style.contentTypeText">
                {{ $t('common.movies') }}
              </small>

              <small v-else-if="isFirstSerialContentItem(option, index)" :class="$style.contentTypeText">
                {{ $t('common.serials') }}
              </small>

              <search-widget-content-card
                ref="allLinks"
                :index="index"
                :to="getContentRoute(option)"
                :option="option"
                :search-text="searchQueryText"
                :class="{
                  [$style.contentCard]: true,
                  [$style.activeContentCard]: index === activeContentCardIndex,
                }"
                @mouseover="onMouseover(index)"
              />
            </div>

            <app-loader :is-loaded="!isSearchRequestPending" />

            <div ref="allResultsLinkEl" :class="$style.allResultsBlock">
              <app-link
                v-if="searchResults.length > ConstantsConfigInstanceWeb.getProperty('contentSearchMaxItems')"
                :to="allResultsPageRoute"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Search, 'allResultsLink')"
                :class="$style.allResults"
                @click="onGotoSearchPageClick"
                @mouseover="onMouseover"
                @keydown.up.prevent="onKeydownArrowUp"
              >
                <span :class="$style.allResultsText">{{ $t('searchPage.allResults') }}</span>
                <app-slot-button>
                  <icon-arrow size="medium" direction="right" :class="$style.allResultsIcon" />
                </app-slot-button>
              </app-link>
            </div>
          </div>

          <p v-if="resultNotFound && searchQueryText" :class="$style.noResultMessage">
            {{ $t('searchPage.resultNotFoundYourRequest') }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { useSearchPageAnalytics } from '@package/sdk/src/analytics';
import type { Media } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import { onClickOutside } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import type { ComponentPublicInstance } from 'vue';
import { computed, nextTick, ref, watch } from 'vue';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { EXCLUDED_ROUTES, getSearchPlaceholdersArray } from '@/code/search/search';
import { AppRoute } from '@/platform/router/routes';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import useHostVariation from '@/platform/variation/use-host-variation';
import { useSearchContentStore } from '@/stores/use-search-content-store';

import getKmzaPageValue from '../code/kmza/get-kmza-page-value';
import AppLoader from './app-loader/AppLoader.vue';
import IconArrow from './icons/IconArrow.vue';
import IconSearch from './icons/IconSearch.vue';
import SearchWidgetContentCard from './SearchWidgetContentCard.vue';
import AppInput from './ui/AppInput.vue';
import AppLink from './ui/AppLink.vue';
import AppSlotButton from './ui/AppSlotButton.vue';

const router = useRouter();
const route = useRoute();
const searchContentStore = useSearchContentStore();
const searchPageAnalytics = useSearchPageAnalytics();

const { getContentRoute } = useRouteUtils();
const { injectAdriverScript } = useKmzaDriverEvents();

const { appLanguage } = useHostVariation();

const { searchText, previousSearchText, searchResults, isSearchRequestPending, isSemanticRequestType } =
  storeToRefs(searchContentStore);

let semanticSearchTimerId: number;

const isSearchOpen = ref(false);
const resultNotFound = ref(false);
const searchQueryText = ref(searchText);

const searchInputWrapperRef = ref<HTMLDivElement>();
const searchInputEl = ref<
  ComponentPublicInstance<{
    inputEl: HTMLInputElement;
  }>
>();

const contentLinksListRef = ref<HTMLAnchorElement[]>([]);
const allResultsLinkEl = ref<HTMLAnchorElement>();
const allLinks = ref<HTMLAnchorElement[]>();

const activeContentCardIndex = ref<number | undefined>(0);
const minCursorIndex = 0;
const maxCursorIndex = computed(() => allLinks.value?.length || 0);

const isSearchPageRoute = computed(() => route.name === AppRoute.Search);
const isSearchAvailable = computed(() => !EXCLUDED_ROUTES.includes(route.name as AppRoute));

const allResultsPageRoute = computed(() => ({ name: AppRoute.Search, query: { q: searchQueryText.value } }));
const isSearchResultDropdownShow = computed(() => searchData?.value?.length && !isSearchPageRoute.value);

const searchData = computed(() => {
  const unsortedSearchData = searchResults.value.slice(
    0,
    ConstantsConfigInstanceWeb.getProperty('contentSearchMaxItems'),
  );
  const movieResults = [];
  const serialResults = [];

  for (const searchItem of unsortedSearchData) {
    const contentType = searchItem.contentType;
    if (!contentType) {
      return;
    }

    if (contentType === MediaContentType.MOVIE) {
      movieResults.push(searchItem);
    }

    if (contentType === MediaContentType.SERIAL) {
      serialResults.push(searchItem);
    }
  }

  if (!movieResults.length && !serialResults.length) {
    return [];
  }

  return [...movieResults, ...serialResults];
});

const focusOnSearchInput = () => {
  const searchInput = searchInputEl.value?.$refs.inputEl as HTMLInputElement;

  if (!searchInput) {
    return;
  }

  searchInput?.focus();
};

const focusOnAllResultsLink = () => {
  const allResultsLink = allResultsLinkEl.value?.children[0] as HTMLElement;

  if (!allResultsLink) {
    return;
  }

  allResultsLink?.focus();
};

const clearSearch = () => {
  searchContentStore.setSearchResults([]);
  searchQueryText.value = '';
  searchContentStore.setPreviousSearchText('');
  searchContentStore.setIsSemanticRequestType(false);
};

const searchPlaceholdersArray = getSearchPlaceholdersArray(appLanguage.value);

const getRandomPlaceholder = () => searchPlaceholdersArray[Math.floor(Math.random() * searchPlaceholdersArray.length)];

const debouncedTextSearchFunc = debounce(async (text: string) => {
  previousSearchText.value = text;
  resultNotFound.value = false;

  if (!text.length) {
    if (searchQueryText.value) {
      clearSearch();
    }

    return;
  }

  searchPageAnalytics.onSearchRequestEntering(text);

  const response = isSearchPageRoute.value
    ? await searchContentStore.applySearchSemanticRequest(text)
    : await searchContentStore.applySearchRequest(text);

  if (!response || !response.length) {
    resultNotFound.value = true;
    searchContentStore.setSearchResults([]);
    return;
  }

  if (!searchQueryText.value) {
    searchContentStore.setSearchResults([]);
    return;
  }

  searchContentStore.setSearchResults(response);
  searchPageAnalytics.onSearchResultsDisplayed(text, response, isSemanticRequestType.value);
}, 500);

const onSearchButtonClick = async () => {
  injectAdriverScript(AdriverAnalyticEvent.Search);

  isSearchOpen.value = true;
  activeContentCardIndex.value = undefined;

  await nextTick();

  focusOnSearchInput();
};

const onInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  searchQueryText.value = target.value;

  searchContentStore.setSearchRequestPending(true);

  if (searchQueryText.value === previousSearchText.value) {
    return;
  }

  if (!searchQueryText.value) {
    searchContentStore.setSearchResults([]);
  }

  debouncedTextSearchFunc(searchQueryText.value);

  if (semanticSearchTimerId) {
    window.clearTimeout(semanticSearchTimerId);
  }

  semanticSearchTimerId = window.setTimeout(() => {
    // semanticSearchFunc(searchQueryText.value);
  }, 2000);
};

const onKeydownArrowUp = () => {
  if (activeContentCardIndex.value === 0) {
    activeContentCardIndex.value = undefined;
    return;
  }

  if (activeContentCardIndex.value && activeContentCardIndex.value > minCursorIndex) {
    activeContentCardIndex.value--;
  }

  if (activeContentCardIndex.value && activeContentCardIndex.value < maxCursorIndex.value) {
    focusOnSearchInput();
  }
};

const onKeydownArrowDown = () => {
  if (activeContentCardIndex.value === undefined) {
    activeContentCardIndex.value = 0;
    return;
  }

  if (activeContentCardIndex.value < maxCursorIndex.value) {
    activeContentCardIndex.value++;
  }

  if (activeContentCardIndex.value === maxCursorIndex.value) {
    focusOnAllResultsLink();
  }
};

const onClearInputClick = () => {
  searchPageAnalytics.onClickSearchRequestCancel(searchQueryText.value);

  clearSearch();

  focusOnSearchInput();
};

const onEnter = () => {
  if (!searchQueryText.value) {
    return;
  }

  isSearchOpen.value = false;

  if (activeContentCardIndex.value === undefined) {
    router.push(allResultsPageRoute.value);
  } else if (activeContentCardIndex.value < maxCursorIndex.value) {
    if (!searchData.value) {
      return;
    }

    router.push(getContentRoute(searchData.value[activeContentCardIndex.value]));
  }
};

const onMouseover = (index: number) => {
  activeContentCardIndex.value = index;
};

onClickOutside(searchInputWrapperRef, () => {
  isSearchOpen.value = false;
});

const onGotoSearchPageClick = () => {
  const page = getKmzaPageValue(route.name as AppRoute);

  searchPageAnalytics.onGotoSearchPage({
    request: searchQueryText.value,
    from: page,
    page,
  });
};

const onRouteChanged = () => {
  if (isSearchPageRoute.value) {
    return;
  }

  clearSearch();
};

const isFirstMovieContentItem = (option: Media, index: number) => {
  if (!searchData.value?.length) {
    return false;
  }

  if (index === 0) {
    return option.contentType === MediaContentType.MOVIE;
  }
};

const isFirstSerialContentItem = (option: Media, index: number) => {
  if (!searchData.value?.length) {
    return false;
  }

  if (index === 0) {
    return option.contentType === MediaContentType.SERIAL;
  }

  return (
    searchData.value[index - 1]?.contentType === MediaContentType.MOVIE &&
    option.contentType === MediaContentType.SERIAL
  );
};

defineExpose({ isSearchOpen });

watch(route, onRouteChanged);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../assets/breakpoints' as breakpoints;

.searchWidget {
  display: flex;
  width: 40px;
  justify-content: flex-end;
  transition: width 150ms;
}

.searchButton {
  width: 40px;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary-80);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-states-bg-secondary-hover-70);
  }
}

.searchWidgetOpened {
  width: 280px;
}

.searchWrapper {
  position: relative;
  width: 100%;
}

.allResultsBlock {
  &:hover {
    :global {
      button {
        animation: headShake;
        animation-duration: 3s;
        animation-delay: 1s;
        animation-iteration-count: 1;
      }
    }
  }
}

.searchInputWrapper {
  position: relative;
  height: 40px;
  border-radius: var(--g-border-round-12);
  overflow: hidden;
  text-align: left;
}

.searchInputIcon {
  position: absolute;
  transform: translate(10px, 8px);

  circle,
  path {
    stroke: var(--color-text-primary);
  }
}

.searchInput {
  position: absolute;
  min-height: 40px;
  height: 40px;
  caret-color: var(--color-text-primary);
}

.clearInputIcon {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: var(--z-index-multiselect-icon);
  cursor: pointer;
}

.contentWrapper {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: var(--z-index-content-search);
  width: 100%;
  border-radius: var(--g-border-round-12);
  border: 0;
  background-color: var(--color-bg-modal);
  overflow: hidden;
}

.contentTypeText {
  @include fonts.WebHeadline-3;
  display: inline-block;
  padding: var(--g-spacing-10) 0 var(--g-spacing-16) var(--g-spacing-16);
  background: transparent;
  color: var(--color-text-secondary);
  text-transform: uppercase;
}

.contentCard:hover {
  background-color: var(--color-bg-tertiary);
}

.activeContentCard {
  outline: none;
  background-color: var(--color-bg-tertiary);
}

.allResults {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-16);
  border-radius: 0 0 var(--g-border-round-12) var(--g-border-round-12);
  color: var(--color-text-primary);
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--color-bg-tertiary);
  }
}

.allResultsText {
  @include fonts.WebLabel-1;
  margin-bottom: 0;
}

.noResultMessage {
  padding: var(--g-spacing-28) var(--g-spacing-16);
}

@include breakpoints.max-width-1024 {
  .searchWidgetOpened {
    width: 280px;
  }
}

@include breakpoints.max-width-800 {
  .searchWidgetOpened {
    width: 100%;
  }
}
</style>
