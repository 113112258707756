import type { ApiFeatureToggleItem, FeatureToggleItem } from '@package/sdk/src/api';
import { FeatureToggleItemMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useFeatureToggleApi() {
  const { $flagsHttp } = useNuxtApp();

  const fetchAllToggles = (withVariant = true): Promise<FeatureToggleItem[]> => {
    return $flagsHttp
      .get<ApiFeatureToggleItem[]>(ENDPOINTS.TOGGLES, {
        query: { with_variant: withVariant },
      })
      .then(FeatureToggleItemMapper.mapMany);
  };

  const fetchToggle = (name: string, withVariant: boolean): Promise<FeatureToggleItem> => {
    return $flagsHttp
      .get<ApiFeatureToggleItem>(ENDPOINTS.TOGGLE_BY_NAME, {
        query: { with_variant: withVariant },
        params: { name },
      })
      .then(FeatureToggleItemMapper.map);
  };

  return { fetchAllToggles, fetchToggle };
}
