import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { FeatureToggle, ParentalControlStep } from '@package/sdk/src/api';
import crypto from 'crypto';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

import { executeWithSafeClientContext } from '@/platform/base/function';
import { CookieName, cookies } from '@/platform/cookies/cookies';
import { AppRoute } from '@/platform/router/routes';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

import useAppCookie from '../../platform/cookies/use-app-cookie';
import useEnvironment from '../../platform/environment/use-environment';
import useParentalControlStore from '../../stores/use-parental-control-store';
import useParentalControl from '../profile/use-parental-control';
import useProfile from '../profile/use-profile';
import useTvCodeConnection from '../tv-code/use-tv-code-connection';

if (process.server) {
  try {
    Object.defineProperty(globalThis, 'crypto', {
      value: {
        getRandomValues: (arr: any) => crypto.randomBytes(arr.length),
      },
    });
  } catch (e) {
    console.error(e);
  }
}

const showAppVersion = (version: string) => {
  console.info(
    '%c Viju website version: ' + '%c '.concat(version || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

export default class CodeApplication {
  constructor() {}

  private unregisterDeprecatedWorker() {
    /**
     * Удаляем старый воркер (который пришел к нам со временем Nuxt2)
     */
    try {
      window.navigator?.serviceWorker?.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          if (registration.scope.includes('sw.js')) {
            registration.unregister();
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  private registerWatchers() {
    const { appVersion } = useEnvironment();

    showAppVersion(appVersion);
  }

  private registerModalChecking() {
    const route = useRoute();
    const layoutStore = useLayoutStore();
    const parentalControlStore = useParentalControlStore();
    const { shouldPassParentalControlPrompt, parentalControlModalState: modalState } = useProfile();
    const { isAuth, currentDeviceParentalControlStatus, isAccountParentalControlSettled } =
      storeToRefs(useSessionStore());

    const { getStatus } = useFeaturesStore();
    const { resolveParentalControlStep } = useParentalControl();

    const { isTvCodePage } = useTvCodeConnection();

    const useQuizModal = computed(() => getStatus({ name: FeatureToggle.QuizModal }));
    const isThankYouPage = computed(() => route.name === AppRoute.ThankYou);

    // Если КВИЗ включен в unleash
    if (useQuizModal.value) {
      const isExceptionPage = computed(() =>
        [
          AppRoute.Auth,
          AppRoute.Offers,
          AppRoute.OffersPromo,
          AppRoute.OffersPay,
          AppRoute.Referral,
          AppRoute.AuthEnter,
          AppRoute.AuthPasswordRestorePhone,
          AppRoute.AuthPasswordLinkExpired,
          AppRoute.AuthEnterPassword,
          AppRoute.AuthPasswordRestoreEmail,
          AppRoute.AuthRestorePassword,
          AppRoute.MyChannel,
          AppRoute.MyChannelMoment,
          AppRoute.TVCodePage,
          AppRoute.GoTVCodePage,
        ].includes(route.name as AppRoute),
      );

      let showQuizModalTimeoutId: number;

      const onAuth = (auth: boolean) => {
        if (showQuizModalTimeoutId) {
          window.clearTimeout(showQuizModalTimeoutId);
        }

        const quizShowedCookie = useAppCookie(CookieName.QuizShowed, { maxAge: cookies.quizShowed.maxAge });

        // Модалку отображаем только для неавторизованных, и если мы еще раньше ее не показывали
        if (auth || quizShowedCookie.value) {
          return;
        }

        showQuizModalTimeoutId = window.setTimeout(() => {
          if (isExceptionPage.value) {
            return;
          }

          quizShowedCookie.value = true;
          layoutStore.setCurrentModalName('QuizModal');
        }, ConstantsConfigInstanceWeb.getProperty('quizModalTimeoutMs'));
      };

      watch(isAuth, onAuth, { immediate: true });
    }

    watch(
      [isThankYouPage, isTvCodePage],
      (isThankYou, isTvCode) => {
        if (isThankYou || isTvCode) {
          if (layoutStore.currentModalName === 'ParentalControlModal') {
            layoutStore.setCurrentModalName(null);
          }
        }
      },
      { immediate: true },
    );

    watch(
      [isAuth, isThankYouPage, shouldPassParentalControlPrompt],
      async ([auth, isThankYou]) => {
        await nextTick();

        if (!auth || isThankYou) {
          return;
        }

        const step = resolveParentalControlStep({
          shouldPassParentalControlPrompt: shouldPassParentalControlPrompt.value,
          currentDeviceParentalControlStatus: currentDeviceParentalControlStatus.value,
          isAccountParentalControlSettled: isAccountParentalControlSettled.value,
          modalState: modalState.value,
        });

        parentalControlStore.setModalStep(step);

        if (step !== ParentalControlStep.Hidden && !isTvCodePage.value) {
          layoutStore.setCurrentModalName('ParentalControlModal', { hasCloseIcon: false });
        }
      },
      { immediate: true },
    );
  }

  private registerEventHandlers() {
    const { $emitter, $contentCacheManager } = useNuxtApp();

    $emitter.on('user.login.complete', () => $contentCacheManager.clear());
    $emitter.on('user.logout.complete', () => $contentCacheManager.clear());
    $emitter.on('user.profile.updated', () => $contentCacheManager.clear());
  }

  public init() {
    this.registerWatchers();

    const { $emitter } = useNuxtApp();
    const offerStore = useOffersStore();

    $emitter.on('offer.loadRequest', () => offerStore.loadOffer());

    executeWithSafeClientContext(() => {
      this.unregisterDeprecatedWorker();
      this.registerModalChecking();
      this.registerEventHandlers();
    });
  }
}
