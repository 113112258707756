import { useAuthPageAnalytics } from '@package/sdk/src/analytics';
import type { IOfferCard, Offer } from '@package/sdk/src/api';
import { OfferCardType, PromoCodeState, RedirectSymbol } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { AnyFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { PromocodeValidationError } from '@/platform/base/errors';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useLogger } from '@/platform/logger/use-logger';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import useAppSessionStorage, { SessionStorageKey } from '@/platform/session-storage/useAppSessionStorage';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLocalStorage } from '@/platform/storage/use-local-storage';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useAuthRegistrationStore } from '@/stores/use-auth-registration-store';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import useParentalControlStore from '@/stores/use-parental-control-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

import { useAdmitadUserId } from '../admitad/use-admitad-user-id';
import { usePromocodeApi } from '../bonus-programs/use-promocode-api';
import { useLikes } from '../collections/use-likes';
import { useLocalCollections } from '../collections/use-local-collections';
import { useKmzaWeboramaEvents, WeboramaAnalyticEvent } from '../kmza/use-webama-kmza';
import { NotificationLevel } from '../notifications/notification';
import type { OAuthResponse } from '../o-auth/types/o-auth';
import { useOAuthResponse } from '../o-auth/useOAuthResponse';
import { usePaymentsActions } from '../payments/use-payments-actions';
import useTvPromoPopupActions from '../tv/use-tv-promo-popup-actions';
import type { IUserCreateSession } from '../user/use-users-api';
import { useUsersApi } from '../user/use-users-api';

export interface MappedBannerOffer {
  title: string;
  bullets: string[];
  infoText: string;
  btnName: string;
  image: string;
}

export interface INavigateToAuthOptions {
  offerId: string;
  offerRedirectUrl?: string;
}

interface SendToRegistrationPageOptions {
  isRedirectFromFreeEpisode?: boolean;
}

const MAX_CARD_COUNT = 3;
const MAX_BULLETS_COUNT = 6;
const LOW_QUALITY_RESOLUTION = 480;

const DEFAULT_IMAGE_URL =
  'https://viasat-small.cdnvideo.ru/viasat/product-x/production/offer_backgrounds/web_default/promo_banner_bg@2x.jpg';

export default function useAuthActions() {
  const route = useRoute();
  const router = useRouter();
  const { translate } = useLocale();
  const isMobile = useMobile();
  const app = useNuxtApp();

  const logger = useLogger();
  const authPageAnalytics = useAuthPageAnalytics();

  const usersApi = useUsersApi();
  const promocodeApi = usePromocodeApi();

  const sessionStore = useSessionStore();
  const offersStore = useOffersStore();
  const promocodeStore = usePromocodeStore();
  const authRegStore = useAuthRegistrationStore();
  const layoutStore = useLayoutStore();
  const parentalControlStore = useParentalControlStore();

  const { isActiveSubscription, isCanceledSubscription } = storeToRefs(offersStore);
  const { isPartnerUser, isAuth, user } = storeToRefs(sessionStore);
  const { promocodeState, isPromocodeCardRequired } = storeToRefs(authRegStore);
  const { appliedBonusInfo, promocodeCheckInfo, savedPromocode } = storeToRefs(usePromocodeStore());
  const localStorage = useLocalStorage();

  const featuresStore = useFeaturesStore();

  const { sendAdmitadEvent } = useAdmitadUserId();
  const { uploadLocalCollections } = useLocalCollections();
  const { uploadLocalLikes } = useLikes();
  const { mapResponseToAuthBody } = useOAuthResponse();
  const { getTvPromoRedirectFlag, showTvPromoPopup, getTvRedirectUrl, removeFlag } = useTvPromoPopupActions();
  const { applyOfferWithPaymentSystem } = usePaymentsActions();
  const { injectWeboramaPixel, removeWeboramaSessionPixels } = useKmzaWeboramaEvents();

  const promocodeCookie = useAppCookie(CookieName.Promocode);

  const isOfferRedirectSkipped = useAppSessionStorage<boolean>(SessionStorageKey.SkipOfferRedirect, false);

  const isPromocodeCheckError = computed(() => promocodeState.value === PromoCodeState.Error);
  const isPromocodeCheckSuccess = computed(() => promocodeState.value === PromoCodeState.Success);
  const isPromocodeCheckRequestLoading = computed(() => promocodeState.value === PromoCodeState.Loading);
  const isPromocodeCheckNotSettled = computed(() => promocodeState.value === PromoCodeState.NotSettled);

  const backUrl = computed(() => route.query[RouteQuery.BackRoute] || '/');
  const isBackUrlToOffers = computed(() => backUrl.value.includes(AppRoute.Offers));

  const DEFAULT_BTN_NAME = translate('offerPage.promoBanner.defaultButtonText');

  const parseBackRoutePathAndQuery = (pathWithQuery: string) => {
    const query: Record<string, string> = {};

    const splitPath = pathWithQuery.split('?');
    let path;
    let queryString;

    if (splitPath.length === 1) {
      return {
        path: splitPath[0],
        query: {},
      };
    }

    if (splitPath.length > 1) {
      path = splitPath[0];
      queryString = splitPath[1];
    } else {
      path = undefined;
      queryString = splitPath[0];
    }

    const pairs = queryString.split('&');

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const queryKey = decodeURIComponent(key) as string;

      query[queryKey] = decodeURIComponent(value || '');
    });

    return { path, query };
  };

  const doRedirectOfferPage = () => {
    const redirectUrl = getOfferPageRoutesToRedirect();

    return navigateTo(redirectUrl);
  };

  const getOfferPageRoutesToRedirect = (backUrl = router.currentRoute.value.fullPath) => {
    if (savedPromocode.value) {
      return {
        name: AppRoute.OffersPromo,
        params: { promocode: savedPromocode.value },
        query: { [RouteQuery.BackRoute]: backUrl },
      };
    }

    return {
      name: AppRoute.Offers,
      query: { [RouteQuery.BackRoute]: backUrl },
    };
  };

  const restoreSavedPromocode = async () => {
    const promocodeQuery = route.query[RouteQuery.Promocode] as string;

    // Если нет сохраненного промокода (не в куках, не в ссылке)
    if (!promocodeCookie.value && !promocodeQuery) {
      return;
    }

    if (promocodeQuery) {
      promocodeCookie.value = promocodeQuery;
    }

    const promocode = promocodeCookie.value;

    try {
      const data = await promocodeApi.getPromocodeData(promocode);
      promocodeStore.setSavedPromocode(promocode);
      promocodeStore.setPromocodeCheckInfo(data);

      authRegStore.setIsPromocodeCardRequired(data.cardRequired);
      authRegStore.setPromocodeState(PromoCodeState.Success);
    } catch (error) {
      logger.error(error);
    }
  };

  const checkPromocode = async (successCardNotRequiredCheckCallback?: AnyFn) => {
    try {
      promocodeState.value = PromoCodeState.Loading;

      const promocodeData = await promocodeApi.getPromocodeData(savedPromocode.value);
      promocodeStore.setPromocodeCheckInfo(promocodeData);

      if (!promocodeData.available) {
        throw new PromocodeValidationError(promocodeData.message, savedPromocode.value);
      }

      const isPromocodeWithCardRequired = promocodeData?.cardRequired;
      authRegStore.setIsPromocodeCardRequired(isPromocodeWithCardRequired);
      authRegStore.setPromocodeState(PromoCodeState.Success);

      promocodeCookie.value = isPromocodeWithCardRequired ? savedPromocode.value : '';

      // Если промокод успешный, но без привякиз карты
      if (!isPromocodeWithCardRequired && successCardNotRequiredCheckCallback) {
        Reflect.apply(successCardNotRequiredCheckCallback, undefined, []);
      }
    } catch (error) {
      const message =
        error instanceof PromocodeValidationError ? error.message : translate('lk.bonuses.promocodeError');

      layoutStore.addNotification({
        level: NotificationLevel.Info,
        message,
        hideTimeoutMs: 3000,
        position: 'top',
      });

      authRegStore.setPromocodeState(PromoCodeState.Error);
      promocodeCookie.value = '';
      promocodeStore.setSavedPromocode('');

      logger.error(error);
    }
  };

  const checkQueryPromocode = (queryPromoCode: string) => {
    authRegStore.setPromocode(queryPromoCode);
    return checkPromocode();
  };

  const applyPromocode = async (): Promise<void> => {
    try {
      const appliedPromocode = await promocodeApi.applyPromocode(savedPromocode.value);
      promocodeStore.setAppliedBonusInfo(appliedPromocode);

      if (!isPromocodeCardRequired.value) {
        promocodeCookie.value = '';
        promocodeStore.setSavedPromocode('');
      }

      if (isPromocodeCardRequired.value) {
        promocodeCookie.value = savedPromocode.value;
      }
    } catch (err) {
      authRegStore.setPromocodeState(PromoCodeState.Error);

      promocodeCookie.value = '';
      promocodeStore.setSavedPromocode('');

      logger.error(err);
    }
  };

  const applyPromocodeOfferWithPaymentSystem = async () => {
    if (isAuth.value && promocodeCheckInfo.value?.available) {
      await applyPromocode();
      promocodeCookie.value = '';
      promocodeStore.setSavedPromocode('');
    }

    const promoOffer = promocodeCheckInfo.value?.promoOffer;

    if (!promoOffer) {
      throw new UnexpectedComponentStateError('promoOffer');
    }

    return applyOfferWithPaymentSystem(promoOffer.id);
  };

  const getBullets = (offer: Offer): string[] => offer.cardBenefits.slice(0, MAX_BULLETS_COUNT);

  const getBackground = (offer: Offer) => {
    const context = { background: '', preBackground: '' };

    context.background = isMobile
      ? (offer.bonusProgramVerticalImage as string) || ''
      : (offer.bonusProgramHorizontalImage as string) || '';
    context.preBackground = `${context.background}?w=${LOW_QUALITY_RESOLUTION}`;

    return context;
  };

  const mapPromoOfferToBanner = (offer: Offer): MappedBannerOffer => {
    const imageUrl = (offer.images?.mainPageOfferHorizontal as string) || DEFAULT_IMAGE_URL;

    return {
      title: offer.cardTitle || '',
      bullets: getBullets(offer),
      infoText: offer.cardPrice || '',
      btnName: offer.texts?.mainPageBannerButton || DEFAULT_BTN_NAME,
      image: imageUrl,
    };
  };

  const mapOfferToCard = (offer: Offer) => ({
    id: offer.id,
    type: OfferCardType.DEFAULT,
    title: offer.cardTitle || '',
    subtitle: offer.promoOfferSubtitle || '',
    cardPrice: offer.cardPrice || '',
    price: String(offer.price) || '',
    platform: offer.platform,
    bullets: getBullets(offer),
    badge: offer.badge || '',
    ...getBackground(offer),
    activeByDefault: offer.markedBest,
    trialDuration: offer.trialDuration,
    trialDurationUnit: offer.trialDurationUnit,
  });

  const mapOffersToCards = (offers: Offer[]): IOfferCard[] => offers.slice(0, MAX_CARD_COUNT).map(mapOfferToCard);

  const getRedirectLinkAfterCheckPromocode = (symbol?: RedirectSymbol): RouteLocationRaw | undefined => {
    switch (symbol) {
      case RedirectSymbol.Index:
        return { name: AppRoute.Index, query: route.query };
      case RedirectSymbol.Offers:
        return { name: AppRoute.Offers, query: route.query };
      case RedirectSymbol.OfferPromo:
        return { name: AppRoute.OffersPromo, query: route.query };
      case RedirectSymbol.Profile:
        return {
          name: AppRoute.AccountBonuses,
          query: route.query,
        };
      case RedirectSymbol.Subscription:
        return {
          name: AppRoute.AccountSubscriptionInfo,
          query: route.query,
        };
      case RedirectSymbol.Auth:
        return {
          name: AppRoute.AuthEnter,
          query: route.query,
        };
      default:
        return undefined;
    }
  };

  const navigateToAuth = (options: INavigateToAuthOptions) => {
    const { offerId, offerRedirectUrl } = options;

    const backPath = route.query[RouteQuery.BackRoute] as string;
    const backUrl = backPath || offerRedirectUrl;

    // После того как юзер "явно" выбрал оффер, мы это запоминаем - и после возврата обратно, отправим сразу в ПС
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({
      name: AppRoute.AuthEnter,
      query: {
        [RouteQuery.BackRoute]: backUrl,
        [RouteQuery.OfferId]: offerId,
      },
    });
  };

  const sendToRegistrationPage = (options: SendToRegistrationPageOptions = {}) => {
    const backUrl = router.currentRoute.value.fullPath;
    const query = { [RouteQuery.BackRoute]: backUrl };

    if (options.isRedirectFromFreeEpisode) {
      Reflect.set(query, RouteQuery.IsRedirectFromFreeEpisode, true);
    }

    navigateTo({
      name: AppRoute.AuthEnter,
      query,
    });
  };

  const navigateToPayment = (offerId: string) => {
    authRegStore.setIsImmediatePaymentRedirect(true);

    navigateTo({ name: AppRoute.Offers, query: { [RouteQuery.OfferId]: offerId } });
  };

  const doUpdateLocalCollections = () => {
    uploadLocalCollections();
    uploadLocalLikes();
  };

  const onDidUserCreateSession = async () => {
    // Обновляем список фича-флагов
    await restoreSavedPromocode();

    // Загружаем локальные коллекции в бек
    doUpdateLocalCollections();
    sendAdmitadEvent();

    app.$http.cache.clear();
    await offersStore.loadOffer();

    if (user.value?.id) {
      app.$yandexMetricaEventSender.setYandexMetricaUserID(user.value.id);
    }
  };

  const onDidUserLogin = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessAuthorization);
  };

  const onDidUserRegister = async () => {
    await onDidUserCreateSession();

    injectWeboramaPixel(WeboramaAnalyticEvent.SuccessRegistration);
  };

  const onDidUserLogout = async () => {
    removeFlag();
    removeWeboramaSessionPixels();

    localStorage.remove(LocalStorageKey.MyChannelOnboardingPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelRegistrationPopupShown);
    localStorage.remove(LocalStorageKey.MyChannelDislikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelLikePopupShown);
    localStorage.remove(LocalStorageKey.MyChannelWatchAndRatePopupShown);

    app.$http.cache.clear();

    authRegStore.reset();
    promocodeStore.reset();
    parentalControlStore.reset();
    authRegStore.setIsImmediatePaymentRedirect(false);
    await restoreSavedPromocode();
    await offersStore.loadOffer();
  };

  const validateTvPromoPopupUrl = () => {
    const tvPromoRedirectUrlAfterReg = getTvPromoRedirectFlag();

    if (!tvPromoRedirectUrlAfterReg) {
      return;
    }

    showTvPromoPopup();
    return getTvRedirectUrl();
  };

  const getValidationRestorePasswordToken = async (token: string) => {
    try {
      await usersApi.checkResetPasswordToken(token);
      return true;
    } catch (error) {
      logger.error(error);

      return false;
    }
  };

  const getPromocodeRedirectRoute = () => {
    const storedPromocode = savedPromocode.value;

    if (!storedPromocode) {
      return undefined;
    }

    // Если нет сохраненного промо-оффера
    if (!promocodeCheckInfo.value?.promoOffer) {
      console.warn('expect to be defined promocodeCheckInfo.promoOffer');
      return undefined;
    }

    // Если нету применненого бонуса
    if (!appliedBonusInfo.value) {
      console.warn('expect to be defined appliedBonusInfo');
      return undefined;
    }

    return {
      name: AppRoute.OffersPromo,
      params: { promocode: storedPromocode },
      query: route.query,
    };
  };

  const getRedirectRouteTvPromoPopup = (): RouteLocationRaw | undefined => {
    const url = validateTvPromoPopupUrl();

    if (!url) {
      return undefined;
    }

    return {
      path: url,
      query: route.query,
    };
  };

  const getBackRouteRedirectUrl = () => {
    const parsedRoute = parseBackRoutePathAndQuery(backUrl.value);

    parsedRoute.query = {
      ...parsedRoute.query,
      ...route.query,
    };

    if (isPromocodeCheckError.value) {
      Reflect.deleteProperty(parsedRoute.query, RouteQuery.OfferId);
    }

    return parsedRoute;
  };

  const getSkippedOfferRedirectRoute = () => {
    if (!isOfferRedirectSkipped.value) {
      return undefined;
    }

    isOfferRedirectSkipped.value = false;

    const backRoute = getBackRouteRedirectUrl();

    return isBackUrlToOffers.value
      ? {
          name: AppRoute.Index,
          query: route.query,
        }
      : backRoute;
  };

  const getRedirectRouteAfterRegistration = async () => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();

    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // Если промокод не активирован, возвращаем на страницу офферов
    if (!isPromocodeCheckSuccess.value) {
      return {
        name: AppRoute.Offers,
        query: route.query,
      };
    }

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      return parseBackRoutePathAndQuery(backUrl.value);
    }

    if (isPromocodeCheckSuccess.value && isPromocodeCardRequired.value) {
      return await getPromocodeRedirectRoute();
    }

    throw new UnexpectedComponentStateError('getNewUserRedirectRoute');
  };

  const getRedirectRouteAfterAuthorization = async (): Promise<RouteLocationRaw | undefined> => {
    const skippedRedirectRoute = getSkippedOfferRedirectRoute();
    if (skippedRedirectRoute) {
      return skippedRedirectRoute;
    }

    const tvRouteRedirectRoute = getRedirectRouteTvPromoPopup();
    if (tvRouteRedirectRoute) {
      return tvRouteRedirectRoute;
    }

    // обычный, была подписка
    if (isCanceledSubscription.value && !isPartnerUser.value) {
      const promocodeRoute = await getPromocodeRedirectRoute();
      if (promocodeRoute) {
        return promocodeRoute;
      }

      const backRoute = getBackRouteRedirectUrl();
      if (backRoute.query[RouteQuery.OfferId] || backRoute.query[RouteQuery.IsRedirectFromFreeEpisode]) {
        return {
          name: AppRoute.Offers,
          query: backRoute.query,
        };
      }

      return backRoute;
    }

    // обычный, активная подписка
    if (isActiveSubscription.value && !isPartnerUser.value) {
      // Если вводил промокод до этого
      if (isPromocodeCheckSuccess.value && promocodeCheckInfo.value) {
        const { redirectPage, redirectMessage } = promocodeCheckInfo.value;
        const redirectLink = getRedirectLinkAfterCheckPromocode(redirectPage);

        if (redirectMessage) {
          layoutStore.addNotification({
            level: NotificationLevel.Info,
            position: 'top',
            message: redirectMessage,
            hideTimeoutMs: 3000,
          });
        }

        return redirectLink;
      }

      const parsedRoute = parseBackRoutePathAndQuery(backUrl.value);

      return isBackUrlToOffers.value ? { name: AppRoute.Index, query: route.query } : parsedRoute;
    }

    // Партнерский, активная подписка
    if (isActiveSubscription.value && isPartnerUser.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value
        ? {
            name: AppRoute.Index,
            query: route.query,
          }
        : backRoute;
    }

    // обычный, без подписки
    if (!isActiveSubscription.value && !isPartnerUser.value) {
      const promocodeRoute = await getPromocodeRedirectRoute();
      if (promocodeRoute) {
        return promocodeRoute;
      }

      const backRoute = getBackRouteRedirectUrl();
      if (backRoute.query[RouteQuery.OfferId]) {
        return {
          name: AppRoute.Offers,
          query: backRoute.query,
        };
      }

      return backRoute;
    }

    // Партнерский, без подписки
    if (!isActiveSubscription.value && isPartnerUser.value) {
      const backRoute = getBackRouteRedirectUrl();

      return isBackUrlToOffers.value
        ? {
            name: AppRoute.Index,
            query: route.query,
          }
        : backRoute;
    }
  };

  const doLoginUser = async () => {
    app.$emitter.emit('user.login.complete');

    const route = await getRedirectRouteAfterAuthorization();
    logger.info('Login user - redirect', route);

    await sessionStore.updateCurrentUser();

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    return route;
  };

  const loginUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.loginUser(body);

    if (isPromocodeCheckSuccess.value) {
      // Если промокод был успешно применен, забываем про всякие редиректы на ТВ попап
      await checkPromocode(() => removeFlag());
      await applyPromocode();
    }

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessAuthorization({ authMethod: 'login' });

    return doLoginUser();
  };

  const doRegisterUser = async () => {
    const route = await getRedirectRouteAfterRegistration();
    logger.info('Registered user - redirect', route);

    app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Auth });

    app.$emitter.emit('user.registration.complete');
    return route;
  };

  const registerUser = async (body: Partial<IUserCreateSession>) => {
    await sessionStore.registerUser(body);

    if (isPromocodeCheckSuccess.value) {
      await checkPromocode(() => removeFlag());
      await applyPromocode();
    }

    if (isPromocodeCheckSuccess.value && !isPromocodeCardRequired.value) {
      await sessionStore.loadSession({ forceLoadUser: false });
    }

    await featuresStore.fetchFeatures();

    authPageAnalytics.onSuccessRegistration({ authMethod: 'login' });

    return doRegisterUser();
  };

  const loginUserSocialAuth = async (response: OAuthResponse) => {
    const session = await sessionStore.loginUserSocialAuth(mapResponseToAuthBody(response));

    if (session.process === 'sign-in') {
      authPageAnalytics.onSuccessAuthorization({ authMethod: 'social_media_login', oauth: response.provider });
      return doLoginUser();
    }

    if (session.process === 'sign-up') {
      authPageAnalytics.onSuccessRegistration({ authMethod: 'social_media_login', oauth: response.provider });
      return doRegisterUser();
    }

    return logger.error(`unexpected session.process value ${session.process}`);
  };

  const deleteUser = async () => {
    try {
      await usersApi.deleteAccount();
      await sessionStore.deleteSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.DeleteAccount });

      app.$emitter.emit('user.logout.complete');
    } catch (error) {
      logger.error(error);
    } finally {
      navigateTo({ name: AppRoute.Index });
    }
  };

  const logoutUser = async () => {
    try {
      sessionStore.forgetSession();
      await featuresStore.fetchFeatures();

      app.$broadcastChannel.postMessage({ event: BroadcastChannelEvent.Logout });

      app.$emitter.emit('user.logout.complete');
    } catch (e) {
      logger.error(e);
    } finally {
      navigateTo({ name: AppRoute.Index });
    }
  };

  return {
    isPromocodeCheckError,
    isPromocodeCheckSuccess,
    isPromocodeCheckNotSettled,
    isPromocodeCheckRequestLoading,
    deleteUser,
    doRedirectOfferPage,
    getOfferPageRoutesToRedirect,
    restoreSavedPromocode,
    getRedirectLinkAfterCheckPromocode,
    getBackRouteRedirectUrl,
    mapPromoOfferToBanner,
    mapOfferToCard,
    mapOffersToCards,
    checkPromocode,
    checkQueryPromocode,
    navigateToPayment,
    navigateToAuth,
    sendToRegistrationPage,
    loginUserSocialAuth,
    applyPromocode,
    loginUser,
    logoutUser,
    registerUser,
    onDidUserLogout,
    applyPromocodeOfferWithPaymentSystem,
    getValidationRestorePasswordToken,
    onDidUserLogin,
    getBullets,
    onDidUserRegister,
    getRedirectRouteAfterRegistration,
    getRedirectRouteAfterAuthorization,
    parseBackRoutePathAndQuery,
  };
}
