import { isClient } from '@vueuse/core';

const boundaryPadding = 10;

type Position = 'left' | 'right';

const calculateLabelPosition =
  (position: Position = 'right') =>
  (rootEl: HTMLElement) => {
    if (!isClient) {
      return {
        top: 0,
      };
    }

    const elRect = rootEl.getBoundingClientRect();

    const desiredTopCenterPointY = elRect.top + window.scrollY + elRect.height;
    const realTopLeftPointY = desiredTopCenterPointY - elRect.height;

    const desiredStartPointX = position === 'right' ? elRect.right : elRect.left;

    const desiredLeftPositionPointX = desiredStartPointX + boundaryPadding;
    const desiredRightPositionPointX = window.document.body.offsetWidth - desiredStartPointX + boundaryPadding;

    const maximalAllowedPointX = window.document.body.offsetWidth - boundaryPadding;

    const countRealPosition = (desiredPositionFirst: number) => {
      if (desiredPositionFirst < boundaryPadding) {
        return boundaryPadding;
      } else if (desiredPositionFirst + boundaryPadding > maximalAllowedPointX) {
        return maximalAllowedPointX;
      } else {
        return desiredPositionFirst;
      }
    };

    const realLeftPositionPointX = countRealPosition(desiredLeftPositionPointX);
    const realRightPositionPointX = countRealPosition(desiredRightPositionPointX);

    if (position === 'right') {
      return {
        top: realTopLeftPointY,
        left: realLeftPositionPointX,
      };
    } else {
      return {
        top: realTopLeftPointY,
        right: realRightPositionPointX,
      };
    }
  };

export default calculateLabelPosition;
