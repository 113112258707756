import { ProfileType } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

export default function useProfile() {
  const sessionStore = useSessionStore();

  const { profiles, parentalControlModalState: parentalControlModalStateStore, user } = storeToRefs(sessionStore);
  const {
    isCanceledSubscription,
    isActiveSubscription,
    hasLinkedCard,
    isSubscriptionExists,
    isSubscriptionOutdated,
    isManagedPaymentSubscription,
  } = storeToRefs(useOffersStore());

  const profile = computed(() =>
    user.value ? profiles.value?.find((p) => p.id === user.value?.currentProfileId) : undefined,
  );

  const mainProfile = computed(() => profiles.value?.find((x) => x.kind === ProfileType.MAIN));

  const parentalControlModalState = computed({
    get: () => parentalControlModalStateStore.value,
    set: (val) => sessionStore.parentalControlModalStateUpdated(val),
  });

  const isChildProfile = computed(() => profile.value?.kind === ProfileType.CHILD);

  const isMainProfile = computed(() => profile.value?.kind === ProfileType.MAIN);

  const hasOrHadSubscription = computed(
    () =>
      isSubscriptionExists.value &&
      (isActiveSubscription.value || isCanceledSubscription.value || isSubscriptionOutdated.value),
  );

  const shouldPassParentalControlPrompt = computed(
    () => hasOrHadSubscription.value && hasLinkedCard.value && isManagedPaymentSubscription.value,
  );

  const isAllowedManageProfile = computed(() => !isChildProfile.value);

  return {
    isAllowedManageProfile,
    parentalControlModalState,
    shouldPassParentalControlPrompt,
    profile,
    mainProfile,
    isMainProfile,
    isChildProfile,
  };
}
