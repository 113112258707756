import revive_payload_client_7PjnpzLVWv from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nvXbdVsKV9 from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Qwit8BM14y from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Vi7v8FGQ4H from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yQnRHoxslW from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_hUfhr4EOz0 from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_@vue+composition-api@1.7.2_vue@3.4.31_typescript@5.5.2___magicast@0.3.4_ro_hea6qpqao63bsk5alkxw2bnpr4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/srv/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_Ft7XZQbFYG from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_4O1wCjOn0D from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.4_rollup@2.79.1_vite@5.3.5_@types+node@18.11.17_sass@1.69.7_4ea4orams6mr3jy6vmhl4jmmay/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_0lwdrKz4HA from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.4_rollup@2.79.1_vite@5.3.5_@types+node@18.11.17_sass@1.69.7_4ea4orams6mr3jy6vmhl4jmmay/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_cvSIqNmtXT from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1.13_eslint@8.57.0_ioredis@_eaijl6kkyd2ap6oeuri7hq2lg4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_YCWKwfVXF6 from "/srv/app/apps/web/src/plugins/i18n.ts";
import fetch_chunk_error_wsemGEFjub from "/srv/app/apps/web/src/plugins/fetch-chunk-error.ts";
import global_variables_injector_57JJW3i2Jo from "/srv/app/apps/web/src/plugins/global-variables-injector.ts";
import http_client_GlLqeBauh7 from "/srv/app/apps/web/src/plugins/http-client.ts";
import sentry_h0fMjCG9AB from "/srv/app/apps/web/src/plugins/sentry.ts";
import analytics_ao9ICGyq1A from "/srv/app/apps/web/src/plugins/analytics.ts";
import o_auth_q4QM5teNWl from "/srv/app/apps/web/src/plugins/o-auth.ts";
import broadcast_channel_E2KTU8l9I0 from "/srv/app/apps/web/src/plugins/broadcast-channel.ts";
import boot_dFreyY2dpY from "/srv/app/apps/web/src/plugins/boot.ts";
export default [
  revive_payload_client_7PjnpzLVWv,
  unhead_nvXbdVsKV9,
  router_Qwit8BM14y,
  payload_client_Vi7v8FGQ4H,
  check_outdated_build_client_yQnRHoxslW,
  plugin_vue3_hUfhr4EOz0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ft7XZQbFYG,
  pwa_icons_4O1wCjOn0D,
  pwa_client_0lwdrKz4HA,
  chunk_reload_client_cvSIqNmtXT,
  i18n_YCWKwfVXF6,
  fetch_chunk_error_wsemGEFjub,
  global_variables_injector_57JJW3i2Jo,
  http_client_GlLqeBauh7,
  sentry_h0fMjCG9AB,
  analytics_ao9ICGyq1A,
  o_auth_q4QM5teNWl,
  broadcast_channel_E2KTU8l9I0,
  boot_dFreyY2dpY
]