import type { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import type { DsmlValue } from '@package/sdk/src/smarttv/services/analytics/analytic-types';

import { logger } from '@/platform/logger/use-logger';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export class GoogleEventSender {
  private isDev: boolean = false;

  public sendEvent(eventName: AnalyticEventNameNew, values: DsmlValue[] = []) {
    if (this.isDev) {
      return;
    }

    try {
      const dict: Record<string, any> = {};

      for (const { value, property } of values) {
        dict[property] = value;
      }

      window.dataLayer.push({ event: eventName, ...dict });
    } catch (error) {
      logger.error(error);
    }
  }

  public initialize(options: { isDev: boolean }) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    this.isDev = options.isDev;
  }
}
