import type { ApiMovie, ApiQuizGenreItem, QuizContentItem, QuizGenreItem } from '@package/sdk/src/api';
import { MovieMapper, QuizGenreItemMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useQuizApi() {
  const { $http } = useNuxtApp();

  const fetchGenres = (): Promise<QuizGenreItem[]> => {
    return $http.get<ApiQuizGenreItem[]>(ENDPOINTS.QUIZ_GENRES).then(QuizGenreItemMapper.mapMany);
  };

  const fetchGenresContents = (query: Record<string, any>): Promise<QuizContentItem[]> => {
    return $http.get<ApiMovie[]>(ENDPOINTS.QUIZ_GENRES_CONTENTS, { query }).then(MovieMapper.mapMany);
  };

  return { fetchGenres, fetchGenresContents };
}
