import type { FeatureToggleItem, FeatureToggleVariantTypeMap } from '@package/sdk/src/api';
import { defaultFeatureToggleValues, FeatureToggle } from '@package/sdk/src/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useFeatureToggleApi } from '@/code/feature-toggle/use-feature-toggle-api';
import { useLogger } from '@/platform/logger/use-logger';

export const useFeaturesStore = defineStore('feature-toggle', () => {
  const features = ref<FeatureToggleItem[]>([]);
  const featureToggleApi = useFeatureToggleApi();
  const { $emitter } = useNuxtApp();
  const logger = useLogger();

  const fetchFeatures = async () => {
    try {
      features.value = await featureToggleApi.fetchAllToggles();
    } catch (error) {
      logger.error(error);

      features.value = [];
    } finally {
      $emitter.emit('app.features.updated');
    }
  };

  const getFeature = (name: FeatureToggle) => {
    const feature = features.value.find((item) => item.name === name);

    return feature;
  };

  const getDefaultFeature = (name: FeatureToggle) => defaultFeatureToggleValues.find((item) => item.name === name);

  const getStatus = ({ forcedStatus, name }: { forcedStatus?: boolean; name?: FeatureToggle }) => {
    const item = getFeature(name!) || getDefaultFeature(name!);
    return forcedStatus ?? item?.status ?? true;
  };

  const getVariant = <T extends FeatureToggle>(
    name: T,
    defaultValue: FeatureToggleVariantTypeMap[T],
  ): FeatureToggleVariantTypeMap[T] => {
    const value = getFeature(name)?.variant?.value;

    if (value) {
      return value as FeatureToggleVariantTypeMap[T];
    }

    return defaultValue;
  };

  return {
    getStatus,
    getVariant,
    getFeature,
    getDefaultFeature,
    fetchFeatures,
    features,
  };
});
