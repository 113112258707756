import type { Channel, ContentMoment, FilteredMedia, Media, Moment } from '@package/sdk/src/api';
import { AvailableContentType } from '@package/sdk/src/api';
import { Disposable } from '@package/sdk/src/core';

import { LRUCache } from '@/platform/base/map';

const transformMomentToMedia = (moment: Moment): Media => {
  return {
    title: moment.contentTitle,
    slug: moment.contentSlug || moment.contentId,
    id: moment.contentId,
    poster: moment.contentPoster || '',
    contentType: moment.contentType,
    countries: [],
    directors: [],
    duration: 0,
    genres: [],
    imdbRating: 0,
    canCreateUGC: false,
    releaseDate: '',
    inUserCollection: moment.inUserCollection || false,
    imdbRatingVoteCount: 0,
    kinopoiskRating: 0,
    kinopoiskRatingVoteCount: 0,
    description: '',
    year: moment.contentYear as number,
    background: moment.preview || '',
    actors: [],
    ageLimit: undefined,
    availability: moment.contentAvailability || AvailableContentType.AVAILABLE,
  };
};

type CachedContent = Media | Media[] | FilteredMedia | Channel;

export class ContentCacheManager extends Disposable {
  private readonly controller: LRUCache<string, CachedContent> = new LRUCache<string, CachedContent>(100, 1);

  constructor() {
    super();
  }

  public hasCachedBySlug(slug: string): boolean {
    return this.controller.has(slug);
  }

  public clear() {
    this.controller.clear();
  }

  public has(content: Media | Moment | ContentMoment): boolean {
    const moment = content as Moment;

    if (moment.contentType && moment.contentSlug && moment.contentId) {
      const transformedMedia = transformMomentToMedia(moment);

      return this.controller.has(transformedMedia.slug);
    }

    const media = content as Media;
    return this.controller.has(media.slug);
  }

  public getTvFromCache(id: string): Channel {
    return this.controller.get(id) as Channel;
  }

  public addManyToCache(content: Media[], key: string): void {
    return this.controller.set(key, content as Media[]);
  }

  public addFilteredMediaToCache(content: FilteredMedia, key: string): void {
    return this.controller.set(key, content as FilteredMedia);
  }

  public addToCache(content: Channel | Media | Moment | ContentMoment | Media[]): void {
    const moment = content as Moment;

    if (moment.contentType && moment.contentSlug && moment.contentId) {
      const transformedMedia = transformMomentToMedia(moment);

      return this.controller.set(transformedMedia.slug, transformedMedia);
    }

    const media = content as Media | Channel;
    this.controller.set(media.slug || media.id, media);
  }

  public getManyFromCache(id: string): Media[] | undefined {
    return this.controller.get(id) as Media[];
  }

  public getFilteredMediaFromCache(id: string): FilteredMedia | undefined {
    return this.controller.get(id) as FilteredMedia;
  }

  public getFromCache(id: string): Channel | Media | undefined {
    return this.controller.get(id) as Media | Channel;
  }
}
