import { ApplicationError } from './application-error';

export class InvalidPlaylistError extends ApplicationError {
  public readonly name = 'InvalidPlaylistError';

  constructor(private readonly playlist: string) {
    super(`Playlist ${playlist} invalid`);
  }

  public toJSON(): Record<string, any> {
    const { playlist } = this;

    return {
      playlist,
    };
  }
}
