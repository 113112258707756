import type { ApiStarring, Starring } from '@package/sdk/src/api';
import { getPersonName } from '@package/sdk/src/api';

import { ProcessingMapper } from '../base/processing-mapper';

export class StarringMapper {
  static mapMany(writers: ApiStarring[]): Starring[] {
    return ProcessingMapper.process(writers.map(StarringMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiStarring): Starring {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
