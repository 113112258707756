<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 1.875C2.65482 1.875 2.375 2.15482 2.375 2.5C2.375 2.84518 2.65482 3.125 3 3.125H9.25C9.59518 3.125 9.875 3.40482 9.875 3.75V8C9.875 8.34518 10.1548 8.625 10.5 8.625C10.8452 8.625 11.125 8.34518 11.125 8V3.75C11.125 2.71447 10.2855 1.875 9.25 1.875H3ZM1 4.75C1 4.33579 1.33579 4 1.75 4H8.25C8.66421 4 9 4.33579 9 4.75V9.25C9 9.66421 8.66421 10 8.25 10H1.75C1.33579 10 1 9.66421 1 9.25V4.75ZM4.05884 7.94761V6.05268C4.05884 5.8593 4.26897 5.73914 4.43563 5.83721L6.04565 6.78468C6.20992 6.88135 6.20992 7.11893 6.04565 7.2156L4.43563 8.16306C4.26897 8.26114 4.05884 8.14098 4.05884 7.94761Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.5 1.875C3.01675 1.875 2.625 2.26675 2.625 2.75C2.625 3.23325 3.01675 3.625 3.5 3.625H12.875C13.4273 3.625 13.875 4.07272 13.875 4.625V11C13.875 11.4832 14.2668 11.875 14.75 11.875C15.2332 11.875 15.625 11.4832 15.625 11V4.625C15.625 3.10622 14.3938 1.875 12.875 1.875H3.5ZM0.5 6.125C0.5 5.50368 1.00368 5 1.625 5H11.375C11.9963 5 12.5 5.50368 12.5 6.125V12.875C12.5 13.4963 11.9963 14 11.375 14H1.625C1.00368 14 0.5 13.4963 0.5 12.875V6.125ZM5.08826 10.9214V8.07901C5.08826 7.78895 5.40346 7.60871 5.65345 7.75582L8.06847 9.17702C8.31489 9.32203 8.31489 9.67839 8.06847 9.8234L5.65345 11.2446C5.40346 11.3917 5.08826 11.2115 5.08826 10.9214Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 4C5.44772 4 5 4.44772 5 5C5 5.55228 5.44772 6 6 6H18.5C19.3284 6 20 6.67157 20 7.5V16C20 16.5523 20.4477 17 21 17C21.5523 17 22 16.5523 22 16V7.5C22 5.567 20.433 4 18.5 4H6ZM2 9.5C2 8.67157 2.67157 8 3.5 8H16.5C17.3284 8 18 8.67157 18 9.5V18.5C18 19.3284 17.3284 20 16.5 20H3.5C2.67157 20 2 19.3284 2 18.5V9.5ZM8.11765 15.8951V12.1053C8.11765 11.7185 8.53792 11.4782 8.87123 11.6744L12.0913 13.5693C12.4198 13.7626 12.4198 14.2378 12.0913 14.4311L8.87123 16.3261C8.53792 16.5222 8.11765 16.2819 8.11765 15.8951Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.50016 4.8335C6.80981 4.8335 6.25016 5.39314 6.25016 6.0835C6.25016 6.77385 6.80981 7.3335 7.50016 7.3335H25.2085C26.4741 7.3335 27.5002 8.35951 27.5002 9.62516V21.6668C27.5002 22.3572 28.0598 22.9168 28.7502 22.9168C29.4405 22.9168 30.0002 22.3572 30.0002 21.6668V9.62516C30.0002 6.9788 27.8549 4.8335 25.2085 4.8335H7.50016ZM1.8335 12.4585C1.8335 11.2849 2.78489 10.3335 3.9585 10.3335H22.3752C23.5488 10.3335 24.5002 11.2849 24.5002 12.4585V25.2085C24.5002 26.3821 23.5488 27.3335 22.3752 27.3335H3.95849C2.78489 27.3335 1.8335 26.3821 1.8335 25.2085V12.4585ZM10.5003 21.5182V16.1492C10.5003 15.6014 11.0957 15.2609 11.5679 15.5388L16.1296 18.2233C16.595 18.4972 16.595 19.1703 16.1296 19.4442L11.5679 22.1287C11.0957 22.4066 10.5003 22.0661 10.5003 21.5182Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
