<template>
  <div ref="carouselEl" :class="$style.carousel" :style="{ height: $toPixel(height) }">
    <div
      :class="{
        [$style.wrapper]: true,
        [$style.wrapperTransition]: slider.isSlidingNextMoment,
      }"
      :style="{ top: $toPixel(slider.top) }"
    >
      <my-channel-moment-card-player-target
        v-for="(moment, index) in moments"
        :key="moment.id"
        :current-index="activeMomentIndex"
        :index="index"
        :height="height"
        :is-video-playing="isVideoPlaying"
        :preview-src="moment.preview"
      />
    </div>
    <div ref="momentEl" :class="$style.playerWrapper">
      <my-channel-moment-card-player
        :moment="activeMoment"
        @ended="onVideoEnd"
        @ready="onPlayerReady"
        @can-play="onCanPlay"
      />
    </div>

    <transition name="fade">
      <div v-if="isLikeIconShown" :class="$style.iconWrapper">
        <icon-thumb-large :class="{ animate__heartBeat: isLikeIconShown }" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { LikeState } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { useWindowSize } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';

import type { CommonMoment } from '@/code/moments/moments';

import useMobile from '../../platform/layout/use-mobile';
import IconThumbLarge from '../icons/IconThumbLarge.vue';
import MyChannelMomentCardPlayer from './MyChannelMomentCardPlayer.vue';
import MyChannelMomentCardPlayerTarget from './MyChannelMomentCardPlayerTarget.vue';

const props = defineProps<{
  moments: CommonMoment[];
  activeMoment: CommonMoment;
  activeMomentIndex: number;
}>();

const emit = defineEmits<{
  (e: 'on-change', index: number): void;
  (e: 'on-ended', plr: VijuPlayer, index?: number): void;
  (e: 'on-ready', plr: VijuPlayer): void;
  (e: 'on-load-more'): void;
  (e: 'on-dbltap'): void;
  (e: 'on-play'): void;
  (e: 'on-can-play', plr: VijuPlayer): void;
}>();

const { height: windowHeight } = useWindowSize();

const isMobile = useMobile();

const timeouts = {
  doubleTapTimeoutId: 0,
  teleportPlayerTimeoutId: 0,
};

const HEADER_AND_DROPDOWN_HEIGHT = isMobile ? 136 : 165;

const height = computed(() => windowHeight.value - HEADER_AND_DROPDOWN_HEIGHT);
const maxHeight = computed(() => height.value * props.moments.length);

const momentEl = ref<HTMLElement>();
const carouselEl = ref<HTMLElement>();
const player = ref<VijuPlayer>();

const slider = reactive({
  top: 0,
  initialTop: 0,
  initialTouchY: 0,
  isMoving: false,
  swipeDiffY: 0,
  lastTapTime: 0,
  isSwipeToBottom: false,
  isSlidingNextMoment: false,
});

const isLikeIconShown = ref(false);
const isVideoPlaying = ref(false);

const teleportPlayerToSlide = () => {
  const targetEl = carouselEl.value?.querySelector('#player-target');
  if (targetEl && momentEl.value) {
    targetEl.appendChild(momentEl.value as HTMLElement);
    player.value?.play();
  }
};

const runMoment = () => {
  if (timeouts.teleportPlayerTimeoutId) {
    window.clearTimeout(timeouts.teleportPlayerTimeoutId);
  }

  timeouts.teleportPlayerTimeoutId = window.setTimeout(() => {
    slider.isSlidingNextMoment = false;
    teleportPlayerToSlide();
  }, ConstantsConfigInstanceWeb.getProperty('teleportPlayerSliderTimeoutMs'));
};

const onVideoEnd = () => {
  const isSingleOneMomentInArray = props.moments.length === 1;
  const cardIndex = isSingleOneMomentInArray ? props.activeMomentIndex : props.activeMomentIndex + 1;

  slider.isSlidingNextMoment = true;
  slider.top = -Math.abs(cardIndex * height.value);

  runMoment();
  emit('on-ended', player.value, cardIndex);
};

const slideMomentWithUserInteraction = () => {
  let normalizedTopValue = slider.top;

  if (slider.isSwipeToBottom) {
    normalizedTopValue -= ConstantsConfigInstanceWeb.getProperty('myChannelSwipeOffsetPx');
  }

  let cardIndex = Math.round(Math.abs(normalizedTopValue / height.value));
  slider.isSlidingNextMoment = true;

  if (cardIndex > props.moments.length - 1) {
    cardIndex = 0;
    slider.isSlidingNextMoment = false;
  }

  slider.top = -Math.abs(cardIndex * height.value);

  if (cardIndex !== props.activeMomentIndex) {
    emit('on-change', cardIndex);
  }
};

const onTouchStart = (event: TouchEvent) => {
  if (slider.isSlidingNextMoment) {
    slider.isSlidingNextMoment = false;
  }

  slider.isMoving = true;
  slider.initialTop = slider.top;
  slider.initialTouchY = event.changedTouches[0].clientY;
};

const onTouchMove = (event: TouchEvent) => {
  if (!slider.isMoving) {
    return;
  }

  event.preventDefault();

  const y = event.changedTouches[0].clientY;
  const diffY = y - slider.initialTouchY;

  slider.swipeDiffY = diffY;

  const negativeMaxHeight = -Math.abs(maxHeight.value);
  const newTopValue = slider.initialTop + diffY;

  if (negativeMaxHeight > newTopValue) {
    slider.top = negativeMaxHeight;
    return;
  }

  if (newTopValue > 0) {
    slider.top = 0;
    return;
  }

  slider.isSwipeToBottom = Math.sign(diffY) === -1;
  slider.top = newTopValue;
};

const onTouchEnd = () => {
  slider.isMoving = false;
  slideMomentWithUserInteraction();
  slider.initialTop = 0;
  handleDoubleTap();
};

const handleDoubleTap = () => {
  const currentTime = Date.now();
  const tapLen = currentTime - slider.lastTapTime;

  if (tapLen < ConstantsConfigInstanceWeb.getProperty('myChannelDoubleTapTimeoutMs') && tapLen > 0) {
    emit('on-dbltap');

    if (props.activeMoment.likeState === LikeState.Like) {
      isLikeIconShown.value = true;

      window.setTimeout(() => {
        isLikeIconShown.value = false;
      }, ConstantsConfigInstanceWeb.getProperty('hideLikeIconTimeoutMs'));
    }
  } else {
    if (timeouts.doubleTapTimeoutId) {
      window.clearTimeout(timeouts.doubleTapTimeoutId);
    }

    timeouts.doubleTapTimeoutId = window.setTimeout(() => {
      window.clearTimeout(timeouts.doubleTapTimeoutId);
    }, ConstantsConfigInstanceWeb.getProperty('myChannelDoubleTapTimeoutMs'));
  }

  slider.lastTapTime = currentTime;
};

watch(
  () => props.activeMoment,
  () => {
    slider.top = -(props.activeMomentIndex * height.value);
    isVideoPlaying.value = false;

    runMoment();

    if (props.activeMomentIndex === props.moments.length - 2) {
      emit('on-load-more');
    }
  },
);

const onPlaying = () => {
  isVideoPlaying.value = true;
  emit('on-play');
};

const onPlayerReady = (plr: VijuPlayer) => {
  plr.on('playing', onPlaying);

  player.value = plr;
  emit('on-ready', plr);
};

const onCanPlay = (plr: VijuPlayer) => {
  emit('on-can-play', plr);
};

onMounted(async () => {
  await nextTick();

  teleportPlayerToSlide();

  // если юзер в мобилке выбрал не первый кином из списка, нужно проскролить до него
  if (props.activeMomentIndex !== 0) {
    slider.top = -Math.abs(props.activeMomentIndex * height.value);
  }

  if (!carouselEl.value) {
    throw new UnexpectedComponentStateError('carouselEl');
  }

  carouselEl.value.addEventListener('touchstart', onTouchStart, { passive: true });
  carouselEl.value.addEventListener('touchmove', onTouchMove);
  carouselEl.value.addEventListener('touchend', onTouchEnd);
});

onBeforeUnmount(() => {
  if (!carouselEl.value) {
    throw new UnexpectedComponentStateError('carouselEl');
  }

  carouselEl.value.removeEventListener('touchstart', onTouchStart);
  carouselEl.value.removeEventListener('touchmove', onTouchMove);
  carouselEl.value.removeEventListener('touchend', onTouchEnd);
});

defineExpose({
  slideMomentWithUserInteraction,
});
</script>

<style lang="scss" module>
.carousel {
  position: relative;
  z-index: var(--z-index-mobile-my-channel-carousel);

  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 40px);
  will-change: transform;
}

.wrapperTransition {
  transition: top 0.3s ease 0s;
}

.playerWrapper {
  height: calc(100vh - var(--app-header-height--mobile) + var(--app-body-offset-padding));
}

.iconWrapper {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}
</style>
