import { AnalyticPageName } from '@package/sdk/src/analytics';

import { AppRoute } from '@/platform/router/routes';

const dict: Record<AppRoute, AnalyticPageName> = {
  [AppRoute.TV]: AnalyticPageName.Tv,
  [AppRoute.TVChannel]: AnalyticPageName.TvChannel,
  [AppRoute.Account]: AnalyticPageName.Account,
  [AppRoute.AccountDevices]: AnalyticPageName.Account,
  [AppRoute.AccountProfiles]: AnalyticPageName.Account,
  [AppRoute.AccountBonuses]: AnalyticPageName.Account,
  [AppRoute.AccountBonusesPromocode]: AnalyticPageName.Account,
  [AppRoute.AccountBonusesReferral]: AnalyticPageName.Account,
  [AppRoute.AccountLanguage]: AnalyticPageName.Account,
  [AppRoute.AccountSubscriptionManage]: AnalyticPageName.Account,
  [AppRoute.AccountSubscriptionInfo]: AnalyticPageName.Account,
  [AppRoute.AccountInfo]: AnalyticPageName.Account,

  [AppRoute.MyChannel]: AnalyticPageName.MyChannel,
  [AppRoute.MyChannelMoment]: AnalyticPageName.MyChannel,

  [AppRoute.Search]: AnalyticPageName.Search,
  [AppRoute.MovieCard]: AnalyticPageName.Movie,
  [AppRoute.SerialCard]: AnalyticPageName.Series,
  [AppRoute.SerialEpisodeCard]: AnalyticPageName.Series,
  [AppRoute.SerialSeasonCard]: AnalyticPageName.Series,

  [AppRoute.MyCollection]: AnalyticPageName.Collections,
  [AppRoute.MyCollectionKinom]: AnalyticPageName.Collections,
  [AppRoute.MyCollectionContent]: AnalyticPageName.Collections,

  [AppRoute.Movies]: AnalyticPageName.Catalog,
  [AppRoute.MovieFilter]: AnalyticPageName.Catalog,
  [AppRoute.Serials]: AnalyticPageName.Catalog,
  [AppRoute.SerialFilter]: AnalyticPageName.Catalog,
  [AppRoute.Index]: AnalyticPageName.Main,
  [AppRoute.CompilationsItem]: AnalyticPageName.Compilation,
  [AppRoute.Compilations]: AnalyticPageName.Collections,
  [AppRoute.PlaylistItem]: AnalyticPageName.Kinom,

  [AppRoute.OffersPay]: AnalyticPageName.Unknown,
  [AppRoute.AuthEnterPassword]: AnalyticPageName.Unknown,
  [AppRoute.Offers]: AnalyticPageName.Unknown,
  [AppRoute.OffersPromo]: AnalyticPageName.Unknown,
  [AppRoute.AuthRestorePassword]: AnalyticPageName.Unknown,
  [AppRoute.InternalServerError]: AnalyticPageName.Unknown,
  [AppRoute.Contacts]: AnalyticPageName.Unknown,
  [AppRoute.Auth]: AnalyticPageName.Unknown,
  [AppRoute.AuthEnter]: AnalyticPageName.Unknown,
  [AppRoute.AuthPasswordRestoreEmail]: AnalyticPageName.Unknown,
  [AppRoute.AuthPasswordRestorePhone]: AnalyticPageName.Unknown,
  [AppRoute.AuthPasswordLinkExpired]: AnalyticPageName.Unknown,
  [AppRoute.Playlists]: AnalyticPageName.Unknown,
  [AppRoute.ThankYou]: AnalyticPageName.Unknown,
  [AppRoute.Sitemap]: AnalyticPageName.Unknown,
  [AppRoute.Referral]: AnalyticPageName.Unknown,
  [AppRoute.RecommendationTechnologies]: AnalyticPageName.Unknown,
  [AppRoute.PrivacyPolicy]: AnalyticPageName.Unknown,
  [AppRoute.UserAgreement]: AnalyticPageName.Unknown,
  [AppRoute.Download]: AnalyticPageName.Unknown,
  [AppRoute.DownloadMobApp]: AnalyticPageName.Unknown,
  [AppRoute.TVCodePage]: AnalyticPageName.Unknown,
  [AppRoute.GoTVCodePage]: AnalyticPageName.Unknown,
  [AppRoute.SandboxUiPage]: AnalyticPageName.Unknown,
  [AppRoute.SandboxIconsPage]: AnalyticPageName.Unknown,
  [AppRoute.SandboxPlayerPage]: AnalyticPageName.Unknown,
  [AppRoute.SandboxDebugPage]: AnalyticPageName.Unknown,
};

export default function getAnalyticPageValue(routeName: AppRoute): AnalyticPageName {
  const value = dict[routeName];

  return value;
}
