import type { ApiWriter, Writer } from '@package/sdk/src/api';
import { getPersonName } from '@package/sdk/src/api';

import { ProcessingMapper } from '../base/processing-mapper';

export class WriterMapper {
  static mapMany(writers: ApiWriter[]): Writer[] {
    return ProcessingMapper.process(writers.map(WriterMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiWriter): Writer {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
