<template>
  <div v-if="variation === 'default'">
    <app-slot-button
      :ref="(comp) => (buttonEl = comp?.$el)"
      :class="$style.appBookmark"
      :title="ariaLabel"
      :aria-label="ariaLabel"
      @click="onClick"
    >
      <icon-checkmark v-if="inCollection" size="medium" :class="$style.check" />
      <icon-bookmark v-else size="medium" :filled="true" :class="$style.controlIcon" />
      <span v-if="isTextShown" :class="$style.text">
        {{ inCollection ? $t('content.inCollection') : $t('content.addToCollection') }}
      </span>
    </app-slot-button>
    <notification-app-label :label-class="$style.label" label-position="right" :focus-el="buttonEl">
      <span v-html="notificationLabelText" />
    </notification-app-label>
  </div>
  <div v-else>
    <app-slot-button :title="ariaLabel" :aria-label="ariaLabel" @click="onClick">
      <icon-bookmark size="large" :filled="inCollection" :class="$style.controlIcon" />
    </app-slot-button>
  </div>
</template>

<script lang="ts" setup>
import { useContentPageAnalytics, useKinomAnalytics } from '@package/sdk/src/analytics';
import type { CollectionItem, Media, Moment } from '@package/sdk/src/api';
import { CollectionContentType } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useCollections } from '@/code/collections/use-collections';
import { useLocalCollections } from '@/code/collections/use-local-collections';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { useLogger } from '@/platform/logger/use-logger';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

import useLocale from '../../platform/localization/use-locale';
import IconBookmark from '../icons/IconBookmark.vue';
import IconCheckmark from '../icons/IconCheckmark.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import NotificationAppLabel from '../ui/NotificationAppLabel.vue';

const props = withDefaults(
  defineProps<{
    isTextShown?: boolean;
    item: Media | Moment;
    variation?: 'default' | 'icon';
    seasonAndEpisode?: {
      season?: number;
      episode?: number;
    };
    contentType: CollectionContentType;
    maxLocalItemsLength?: number;
  }>(),
  {
    isTextShown: true,
    variation: 'default',
    contentType: CollectionContentType.Content,
  },
);

const emit = defineEmits<{
  (e: 'remove'): void;
  (e: 'add'): void;
  (e: 'max-local-content-length'): void;
}>();

const logger = useLogger();

const route = useRoute();

const { translate } = useLocale();

const buttonEl = ref<HTMLElement>();

const { addToCollection, removeFromCollection } = useCollections();
const { hasInStorage, getLocalStorageContentDataLength } = useLocalCollections();

const { isAuth } = storeToRefs(useSessionStore());

const kinomAnalytics = useKinomAnalytics();
const { onClickItemCollectionsSet, onClickItemCollectionsRemove } = useContentPageAnalytics();

const ariaLabel = computed(() =>
  inCollection.value ? translate('content.deleteFromCollection') : translate('content.addToCollection'),
);

const notificationLabelText = computed(() => {
  return inCollection.value ? translate('content.delete') : translate('content.save');
});

const inCollection = ref(false);

const setInCollection = () => {
  inCollection.value = isAuth.value ? props.item.inUserCollection || false : hasInStorage(props.item.id);
};

const onRemoveFromCollection = async () => {
  const item = props.item;
  const moment = props.item as Moment;
  const mediaItem = props.item as Media;

  const success = await removeFromCollection(item.id);

  if (!success) {
    return logger.error('#onRemoveFromCollection');
  }

  if (moment.contentTitle) {
    kinomAnalytics.onClickCollectionsKinomRemove({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });
  } else {
    onClickItemCollectionsRemove({
      title: mediaItem.title,
      contentType: mediaItem.contentType,
      itemId: mediaItem.serialId || mediaItem.id,
      ...props.seasonAndEpisode,
    });
  }

  emit('remove');
};

const onAddToCollection = async () => {
  const moment = props.item as Moment;
  const mediaItem = props.item as Media;

  if (!isAuth.value && props.maxLocalItemsLength) {
    const localCollectionsLength = getLocalStorageContentDataLength();

    if (localCollectionsLength >= props.maxLocalItemsLength) {
      emit('max-local-content-length');

      return;
    }
  }

  const item = {
    id: props.item.id,
    contentType: props.contentType,
    content: props.item,
  } as CollectionItem;

  const success = await addToCollection(item);

  if (moment.contentTitle) {
    kinomAnalytics.onClickCollectionsKinomSet({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });
  } else {
    onClickItemCollectionsSet({
      title: mediaItem.title,
      contentType: mediaItem.contentType,
      itemId: mediaItem.id,
      ...props.seasonAndEpisode,
    });
  }

  if (!success) {
    return;
  }

  emit('add');
};

const onClick = async () => {
  inCollection.value ? await onRemoveFromCollection() : await onAddToCollection();
  setInCollection();
};

watch(
  () => props.item,
  () => {
    setInCollection();
  },
  { immediate: true },
);
</script>

<style lang="scss" module>
.appBookmark {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-8) var(--g-spacing-8);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary);
  font-weight: 400;
  line-height: var(--g-spacing-24);
}

.label {
  max-width: 100px;
}

.check {
  width: 24px;
  height: 24px;
}

.text {
  margin-left: var(--g-spacing-6);
}

.controlIcon {
  cursor: pointer;
}
</style>
