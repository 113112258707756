import { useNuxtApp } from 'nuxt/app';

import { ENDPOINTS } from '@/platform/http';

type IPresenceBody = { phone_number?: string } | { email?: string };

export function usePresenceApi() {
  const { $http } = useNuxtApp();

  const checkPresence = (data: IPresenceBody): Promise<void> => {
    return $http.post<void>(ENDPOINTS.SESSIONS_USER_PRESENCE, data, { signRequest: true });
  };

  return { checkPresence };
}
