import type { ApiOffer, ApiSubscription, Offer, Subscription } from '@package/sdk/src/api';
import { OfferMapper, SubscriptionMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useOffersApi() {
  const { $http } = useNuxtApp();

  const getById = (id: string): Promise<Offer | undefined> => {
    return $http
      .get<ApiOffer>(ENDPOINTS.OFFERS_ID, { params: { id } })
      .then(OfferMapper.map)
      .catch(() => undefined);
  };

  const getAll = (bonusId?: string): Promise<Offer[]> => {
    return $http.get<ApiOffer[]>(ENDPOINTS.OFFERS, { query: { user_bonus_id: bonusId } }).then(OfferMapper.mapMany);
  };

  const toggleAutoRenew = (id: string, cancelReasonsIds?: string[], reasonText?: string): Promise<Subscription> => {
    return $http
      .patch<ApiSubscription>(
        ENDPOINTS.SUBSCRIPTION_TOGGLE_AUTO_RENEW,
        { cancel_reason_ids: cancelReasonsIds, cancel_reason: reasonText || undefined },
        { params: { id } },
      )
      .then(SubscriptionMapper.map);
  };

  // работает только с продовским API
  const cancelSberAutoRenew = (id: string): Promise<Subscription> => {
    return $http
      .patch<ApiSubscription>(ENDPOINTS.SUBSCRIPTION_SBER_CANCEL_AUTO_RENEW, {}, { params: { id } })
      .then(SubscriptionMapper.map);
  };

  return {
    getAll,
    getById,
    toggleAutoRenew,
    cancelSberAutoRenew,
  };
}
