import type { Ref } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';

export default function useMousePresenceDetection(element: Ref<HTMLElement | undefined>) {
  const isMouseIn = ref(false);

  const mouseEnterHandler = () => {
    isMouseIn.value = true;
  };

  const mouseLeaveHandler = () => {
    isMouseIn.value = false;
  };

  onMounted(() => {
    element.value?.addEventListener('pointerenter', mouseEnterHandler);
    element.value?.addEventListener('pointerleave', mouseLeaveHandler);
  });

  onBeforeUnmount(() => {
    element.value?.removeEventListener('pointerenter', mouseEnterHandler);
    element.value?.removeEventListener('pointerleave', mouseLeaveHandler);
  });

  return {
    isMouseIn,
  };
}
