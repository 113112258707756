import type { Media } from '@package/sdk/src/api';
import { defineStore } from 'pinia';
import { v4 } from 'uuid';
import { ref } from 'vue';

import { useSearchApi } from '@/code/search/use-search-api';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { useLogger } from '@/platform/logger/use-logger';

export const useSearchContentStore = defineStore('search-content', () => {
  const searchApi = useSearchApi();
  const logger = useLogger();
  const visitorId = useAppCookie(CookieName.VisitorId, { default: () => v4() });

  const searchResults = ref<Media[]>([]);
  const setSearchResults = (results: Media[]) => {
    searchResults.value = results;
  };

  const searchText = ref('');
  const setSearchText = (value: string) => {
    searchText.value = value;
  };

  const previousSearchText = ref('');
  const setPreviousSearchText = (value: string) => {
    previousSearchText.value = value;
  };

  const isSearchRequestPending = ref(false);
  const setSearchRequestPending = (value: boolean) => {
    isSearchRequestPending.value = value;
  };

  const isSemanticRequestType = ref(false);
  const setIsSemanticRequestType = (value: boolean) => {
    isSemanticRequestType.value = value;
  };

  const cancelSearchRequest = () => searchApi.cancelSemanticSearchRequest();

  const applySearchSemanticRequest = async (text: string): Promise<Media[] | undefined> => {
    try {
      setIsSemanticRequestType(true);

      const result = await applySearchRequest(text);

      return result;
    } finally {
      setIsSemanticRequestType(false);
    }
  };

  const applySearchRequest = async (text: string): Promise<Media[] | undefined> => {
    isSearchRequestPending.value = true;

    cancelSearchRequest();

    try {
      const data = await searchApi.getContentSemanticSearch(text, visitorId.value || '', true);

      return data;
    } catch (error) {
      logger.error(error);
    } finally {
      isSearchRequestPending.value = false;
    }
  };

  return {
    searchResults,
    searchText,
    previousSearchText,
    isSearchRequestPending,
    isSemanticRequestType,
    applySearchSemanticRequest,
    setSearchRequestPending,
    setIsSemanticRequestType,
    setSearchResults,
    setSearchText,
    setPreviousSearchText,
    cancelSearchRequest,
    applySearchRequest,
  };
});
