<template>
  <!-- Модалка с причинами отписки  -->
  <app-modal-wrapper
    :max-width="480"
    :data-tid="
      $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelReasonsModalWrapper')
    "
  >
    <modal-title :title="$t('lkPage.disableAutoRenewModalTitleQuestion')" />

    <div v-if="cancelReasons.length" :class="$style.reasons">
      <app-checkbox
        v-for="reason in cancelReasons"
        :key="reason.id"
        v-model="selectedReasons"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelReasonsItem')"
        :class="{
          [$style.reasonItem]: true,
          [$style.lastReasonItem]: isReasonTextActive && reason.position === lastReasonPosition,
        }"
        :style="{ order: reason.position }"
        :label="reason.title"
        :value="reason"
      />
      <app-textarea
        v-if="isReasonTextActive"
        v-model="cancelSubscriptionUserText"
        :style="{ order: lastReasonPosition + 1 }"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelReasonsTextarea')
        "
        :class="$style.reasonText"
        :placeholder="$t('lkPage.questionDisableRenewModal')"
        :max-length="999"
        @input="reasonOnInput"
      >
        <template v-slot:after>
          <span
            v-if="showReasonLimitError"
            :data-tid="
              $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelReasonsLimitErrror')
            "
            :class="$style.reasonLimitError"
          >
            {{ $t('lkPage.reasonsLimitTextarea') }}
          </span>
        </template>
      </app-textarea>
    </div>
    <div v-else :class="$style.loader">
      <app-loader />
    </div>

    <div :class="$style.buttons">
      <app-button
        :aria-label="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        :text="$t('lkPage.rejectDisableRenew')"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'rejectDisableRenewButton')
        "
        @click="cancelReasonsKeep"
      />

      <app-button
        :disabled="showReasonLimitError"
        variation="link-alert"
        :aria-label="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :text="$t('lkPage.disableSubscription')"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'disableSubscriptionButton')
        "
        @click="showFinalCancelModal()"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useSubscriptionAnalytics } from '@package/sdk/src/analytics';
import type { CancelReason } from '@package/sdk/src/api';
import { isString, isUndefined } from '@package/sdk/src/core';
import { computed, ref } from 'vue';

import { useSubscriptionCancel } from '@/code/subscription/use-subscription-cancel';
import { useSubscriptionCancelApi } from '@/code/subscription/use-subscription-cancel-api';
import { useLogger } from '@/platform/logger/use-logger';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

import AppButton from '../ui/AppButton.vue';
import AppCheckbox from '../ui/AppCheckbox.vue';
import AppTextarea from '../ui/AppTextarea.vue';
import ModalTitle from './ModalTitle.vue';

const { fetchReasons } = useSubscriptionCancelApi();

const subscriptionAnalytics = useSubscriptionAnalytics();
const layoutStore = useLayoutStore();
const logger = useLogger();

const {
  canUseCancelSubscriptionBonus,
  selectedReasons,
  cancelSubscriptionUserText,
  userHasInteractWithModals,
  cancelTextTitle,
  cancelTextSubtitle,
  cancelTextAcceptButton,
  cancelTextRefuseButton,
  setModalView,
  safeCheckCancelSubscriptionBonus,
} = useSubscriptionCancel();

const cancelReasons = ref<CancelReason[]>([]);
const showReasonLimitError = ref(false);

const MAX_REASON_LENGTH = 1000;

const router = useRouter();

onMounted(async () => {
  subscriptionAnalytics.onShowSubscribeCancelReasons();

  try {
    cancelReasons.value = await fetchReasons();
    await fetchSubscriptionBonusesTexts();
  } catch (e) {
    logger.log(e);
    cancelReasons.value = [];
  }
});

const fetchSubscriptionBonusesTexts = async () => {
  try {
    await safeCheckCancelSubscriptionBonus();
    setModalView('cancelConfirm', true);
  } catch (e) {
    logger.error(e);
  }
};

const isReasonTextActive = computed(() =>
  selectedReasons.value.find((cancelReason) => cancelReason.position === lastReasonPosition.value),
);

const lastReasonPosition = computed(() => {
  const reasons = cancelReasons.value?.map((cancelReason) => cancelReason.position);

  if (isUndefined(reasons)) {
    return 0;
  }

  return Math.max(...reasons);
});

const showFinalCancelModal = async () => {
  await fetchSubscriptionBonusesTexts();
  const reasons = selectedReasons.value.map((reason) => reason.title);

  if (isString(cancelSubscriptionUserText.value)) {
    reasons.push(cancelSubscriptionUserText.value);
  }

  subscriptionAnalytics.onClickSubscribeCancelReasonsProceed(reasons);

  const modalName = canUseCancelSubscriptionBonus.value
    ? 'cancelSubscriptionWithBonus'
    : 'cancelSubscriptionWithoutBonus';
  const reasonText = isReasonTextActive.value ? cancelSubscriptionUserText.value : undefined;

  setModalView(modalName, true);
  layoutStore.setCurrentModalName('BonusDisableAutoRenewalModal', {
    hasCloseIcon: true,
    options: {
      reasonText,
      modalName,
      userSelectedReasons: selectedReasons.value,
      cancelTextTitle: cancelTextTitle.value,
      cancelTextSubtitle: cancelTextSubtitle.value,
      cancelTextAcceptButton: cancelTextAcceptButton.value,
      cancelTextRefuseButton: cancelTextRefuseButton.value,
      cancelSubscriptionUserText: cancelSubscriptionUserText.value,
      canUseCancelSubscriptionBonus: canUseCancelSubscriptionBonus.value,
    },
  });
};

const cancelReasonsKeep = () => {
  cancelSubscriptionUserText.value = '';
  userHasInteractWithModals.value = true;
  setModalView('cancelConfirm', false);

  subscriptionAnalytics.onClickSubscribeCancelReasonsStay();

  // Человек решил остаться, уводим его на главную
  router.push({ name: AppRoute.Index });
  layoutStore.setCurrentModalName(null);
};
const reasonOnInput = (value?: string) => {
  showReasonLimitError.value = Boolean(value && value.length >= MAX_REASON_LENGTH);
};
</script>

<style lang="scss" module>
.reasons {
  display: grid;
  margin: var(--g-spacing-16) 0 var(--g-spacing-32);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary);
  grid-template-columns: 1fr;
  row-gap: var(--g-spacing-10);
  flex-direction: column;
}

.reason-item {
  margin-bottom: 26px;
}

.lastReasonItem {
  margin-bottom: var(--g-spacing-16);
}

.reasonText {
  height: 56px;
  max-height: 200px;
  background-color: var(--color-bg-field);
  color: var(--color-text-primary);
  min-height: 56px;
  padding-top: 18px;
}

.reasonText::placeholder {
  color: var(--color-notheme-text-secondary);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
}

.reasonLimitError {
  color: var(--color-text-negative);
}

.loader {
  position: relative;
  height: 100px;
}
</style>
