import { BaseOAuthProvider } from '@/code/o-auth/base-provider';
import { OAuthProviderType } from '@/code/o-auth/o-auth';
import type { OAuthContext } from '@/code/o-auth/types/o-auth';
import { VkOAuthProvider } from '@/code/o-auth/vk-provider';
import { YandexOAuthProvider } from '@/code/o-auth/yandex-provider';
import { useLogger } from '@/platform/logger/use-logger';

export default defineNuxtPlugin(() => {
  const route = useRoute();
  const logger = useLogger();

  const providers: Record<OAuthProviderType, BaseOAuthProvider> = {
    [OAuthProviderType.Yandex]: new YandexOAuthProvider(),
    [OAuthProviderType.VK]: new VkOAuthProvider(),
  };

  const oAuth = {
    providers,
    initResponseUriHandler() {
      const { provider, state } = route.query;
      const providerType = (provider || state) as OAuthProviderType;

      try {
        this.providers[providerType]?.handleOAuthResponseUri(route.fullPath);
      } catch (e) {
        logger.error(e);
      }
    },
  } as OAuthContext;

  return { provide: { oAuth } };
});
