<template>
  <section :class="$style.menu">
    <app-header-catalog-column
      :current-content-route="currentContentRoute"
      :columns="3"
      :title="$t('catalog.filter.title.genre')"
      :class="$style.genres"
      :items="genres"
    />
    <app-header-catalog-column
      :current-content-route="currentContentRoute"
      :columns="2"
      :grid-layout="false"
      :title="$t('catalog.filter.title.periods')"
      :class="$style.periods"
      :items="years"
    />
    <app-header-catalog-column
      :current-content-route="currentContentRoute"
      :columns="1"
      :title="$t('catalog.filter.title.country')"
      :items="countries.slice(0, 2)"
    />
    <app-header-catalog-column
      v-if="!isRelease"
      title="Меню отладки"
      :columns="1"
      :current-content-route="currentContentRoute"
      :items="[]"
    >
      <li v-for="item in debugMenuItems" :key="item.route">
        <app-link :class="$style.link" :to="{ name: item.route }">
          {{ item.title }}
        </app-link>
      </li>
    </app-header-catalog-column>
  </section>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';

import useEnvironment from '../../platform/environment/use-environment';
import AppLink from '../ui/AppLink.vue';
import AppHeaderCatalogColumn from './AppHeaderCatalogColumn.vue';
import type { ContentRouteName } from './use-app-header-variables';

defineProps<{
  currentContentRoute: ContentRouteName;
}>();

const catalogStore = useCatalogStore();
const { isRelease } = useEnvironment();

const debugMenuItems = [
  { title: 'Настройки', route: AppRoute.SandboxDebugPage },
  { title: 'Компоненты', route: AppRoute.SandboxUiPage },
  // { title: 'Тестовый плеер', route: AppRoute.SandboxPlayerPage },
  { title: 'Иконки', route: AppRoute.SandboxIconsPage },
];

const { countries, genres, years } = storeToRefs(catalogStore);
const menuGridTemplateRows = isRelease ? 'repeat(2, auto)' : 'repeat(3, auto)';
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.menu {
  display: grid;
  grid-template-rows: v-bind(menuGridTemplateRows);
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--g-spacing-10);
  row-gap: var(--g-spacing-32);
  padding: var(--g-spacing-24) var(--g-spacing-32);
  max-width: 626px;
  border-radius: var(--g-border-round-20);
  background-color: var(--color-bg-secondary);
}

.genres {
  grid-column: 1 / 4;
}

.periods {
  grid-row: 2;
  grid-column: 1 / 3;
}

@include breakpoints.max-width-1024 {
  .menu {
    padding: var(--g-spacing-20) var(--g-spacing-24);
    width: 550px;
  }
}
</style>
