import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';

export function useMyChannelAnalytics() {
  const onClickMyChannelFeedSettings = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMyChannelFeedSettings,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
    });
  };

  const onShowMyChannelOnboardPopup = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowMyChannelOnboardPopup,
      page,
    });
  };

  const onClickMyChannelFeedSelect = ({ feed }: { feed: string }) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMyChannelFeedSelect,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
      values: [
        {
          label: AnalyticPayloadLabelName.Feed,
          value: feed,
        },
      ],
    });
  };

  const onGotoMyChannelPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      name: AnalyticEventNameNew.GoToMyChannelPage,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
    });
  };

  const onShowMyChannelPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowMyChannelPage,
      page: window.$dsmlService.getCurrentAnalyticPageValue(),
    });
  };

  return {
    onClickMyChannelFeedSettings,
    onClickMyChannelFeedSelect,
    onShowMyChannelOnboardPopup,
    onShowMyChannelPage,
    onGotoMyChannelPage,
  };
}
