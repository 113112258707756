import type { BreadcrumbsItem } from '@/stores/use-layout-store';

export const useBreadcrumbs = () => {
  const { $emitter } = useNuxtApp();

  const setBreadcrumbs = (breadcrumbs: BreadcrumbsItem[]) => {
    $emitter.emit('app.breadcrumbs.updated', breadcrumbs);
    return Promise.resolve();
  };

  const setDefaultBreadcrumbs = () => {
    $emitter.emit('app.breadcrumbs.updated', []);
    return Promise.resolve();
  };

  return {
    setBreadcrumbs,
    setDefaultBreadcrumbs,
  };
};
