import type { ApiProducer, Producer } from '@package/sdk/src/api';
import { getPersonName } from '@package/sdk/src/api';

import { ProcessingMapper } from '../base/processing-mapper';

export class ProducerMapper {
  static mapMany(producers: ApiProducer[]): Producer[] {
    return ProcessingMapper.process(producers.map(ProducerMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiProducer): Producer {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
