<template>
  <div :class="$style.dotWrapper">
    <span :class="$style.dot" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.dotWrapper {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.dot {
  width: 8px;
  height: 8px;

  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5); // TODO добавить в сдк
}
</style>
