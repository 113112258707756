import type { Compilation, Media, Moment, Serial } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { computed } from 'vue';

import { useLogger } from '@/platform/logger/use-logger';
import { AppRoute } from '@/platform/router/routes';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import type { BreadcrumbsItem } from '@/stores/use-layout-store';

import useLocale from '../../platform/localization/use-locale';
import { useBreadcrumbs } from '../breadcrumbs/use-breadcrumbs';
import { useCompilationApi } from '../compilation/use-compilations-api';
import { useContentMomentsApi } from '../moments/use-content-moments-api';
import { useMovieApi } from '../movie/use-movie-api';
import { useSimilarContentApi } from '../similar-content/use-similar-content-api';
import { useSerialApi } from './use-serial-api';

export interface ICreateCatalogContentTitleOptions {
  season: number;
  episode: number;
  isSeasonPage?: boolean;
  isEpisodePage?: boolean;
}

export interface ICatalogContentManifest {
  src: string;
  token: string;
}

export default function useFetchCatalogContent() {
  const serialApi = useSerialApi();
  const movieApi = useMovieApi();
  const { fetchContentCompilations } = useCompilationApi();
  const similarContentApi = useSimilarContentApi();
  const contentMomentsApi = useContentMomentsApi();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { translate } = useLocale();
  const { createAppLink } = useRouteUtils();

  const router = useRouter();
  const route = computed(() => router.currentRoute.value);
  const logger = useLogger();

  // Что мы находимся на странице фильма
  const isMovieContent = computed(() => route.value.name === AppRoute.MovieCard);
  // Что мы находимся на странице сериала
  const serialPageRoutesArray = [AppRoute.SerialCard, AppRoute.SerialSeasonCard, AppRoute.SerialEpisodeCard];
  const isSerialContent = computed(() => serialPageRoutesArray.includes(route.value.name as AppRoute));
  // ID контента
  const slug = computed(() => route.value.params.id);
  // Номер сезона
  const seasonId = computed(() => route.value.params.seasonId);
  // Номер серии
  const episodeId = computed(() => route.value.params.episodeId);

  // Номер сезона (в integer)
  const paramsSeasonNumber = computed(() => Number(seasonId.value || 1));
  // Номер серии (в integer)
  const paramsEpisodeNumber = computed(() => Number(episodeId.value || 1));

  /**
   * Выставляем хлебные крошки (лежат в футере сайта)
   */
  const setContentBreadcrumbs = async (result: Media) => {
    if (isUndefinedOrNull(result)) {
      throw new UnexpectedComponentStateError('result');
    }

    const contentRoute = isMovieContent.value ? AppRoute.Movies : AppRoute.Serials;
    const { title } = result;

    const breadcrumbLabelName = isMovieContent.value
      ? translate('contentPage.breadcrumbs.movies')
      : translate('contentPage.breadcrumbs.serials');

    const breadcrumbs: BreadcrumbsItem[] = [
      { url: '', label: translate('seo.breadcrumbs.mainPage') },
      { url: createAppLink(contentRoute), label: breadcrumbLabelName },
      { url: createAppLink(contentRoute, slug.value), label: title },
    ];

    if (isSerialContent.value) {
      if (seasonId.value) {
        const seasonRoute = router.resolve({ name: AppRoute.SerialSeasonCard, params: { seasonId: seasonId.value } });

        breadcrumbs.push({
          url: seasonRoute.fullPath,
          label: translate('content.seasons.count1', { count: paramsSeasonNumber.value }),
        });
      }

      if (episodeId.value) {
        const episodeRoute = router.resolve({
          name: AppRoute.SerialEpisodeCard,
          params: { seasonId: seasonId.value, episodeId: episodeId.value },
        });

        breadcrumbs.push({
          url: episodeRoute.fullPath,
          label: translate('content.episode.count1', { count: paramsEpisodeNumber.value }),
        });
      }
    }

    await setBreadcrumbs(breadcrumbs);
  };

  const createCatalogContentTitle = (
    content: Media,
    options: ICreateCatalogContentTitleOptions = { season: 1, episode: 1 },
  ) => {
    const { title: _title } = content;

    const isMovieContent = content.contentType === MediaContentType.MOVIE;
    const { season, episode, isSeasonPage, isEpisodePage } = options;

    if (isMovieContent || (!isSeasonPage && !isEpisodePage)) {
      return _title;
    }

    const serialContent = content as Serial;
    const seasonNumberIndex = serialContent?.seasons.findIndex((_season) => _season.number === season);
    const serialContentSeasonNumber = serialContent?.seasons[seasonNumberIndex]?.number;

    if (serialContentSeasonNumber && serialContentSeasonNumber !== season) {
      return translate('content.serial.titleWithSeasonAndEpisode', {
        title: _title,
        season: serialContentSeasonNumber,
        episode,
      });
    }

    if (isSeasonPage && !isEpisodePage) {
      return translate('content.serial.titleWithSeason', { title: _title, season });
    }

    return translate('content.serial.titleWithSeasonAndEpisode', { title: _title, season, episode });
  };

  const getSeasonLink = (number: number) => {
    return {
      name: AppRoute.SerialSeasonCard,
      params: { id: slug.value, seasonId: number || paramsSeasonNumber.value },
    };
  };

  const getEpisodeLink = (number: number, season?: number) => ({
    name: AppRoute.SerialEpisodeCard,
    params: {
      id: slug.value,
      seasonId: season || paramsSeasonNumber.value,
      episodeId: number || paramsEpisodeNumber.value,
    },
    query: route.value.query,
  });

  const fetchCompilations = async (): Promise<Compilation[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      const compilations = await fetchContentCompilations(slug.value);

      return compilations;
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  const fetchSimilar = async (): Promise<Media[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      return await similarContentApi.fetchItems(slug.value);
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  const fetchMoments = async (): Promise<Moment[]> => {
    if (!slug.value) {
      return [];
    }

    try {
      const moments = await contentMomentsApi.fetchMoments(slug.value);

      return moments;
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  const fetchMovie = () => movieApi.fetchMovie(slug.value);

  const fetchSerial = () => serialApi.fetchSerial(slug.value);

  const fetchMovieManifest = async (id: string): Promise<ICatalogContentManifest> => {
    const manifest = await movieApi.getStreamManifest(id);

    return {
      src: manifest.hls,
      token: manifest.watchingItemToken,
    };
  };

  const fetchSerialManifest = async (id: string): Promise<ICatalogContentManifest> => {
    const manifest = await serialApi.getStreamManifest(id);

    return {
      src: manifest.hls,
      token: manifest.watchingItemToken,
    };
  };

  return {
    seasonId,
    episodeId,
    paramsSeasonNumber,
    paramsEpisodeNumber,
    isMovieContent,
    isSerialContent,
    slug,
    getSeasonLink,
    getEpisodeLink,
    createCatalogContentTitle,
    setContentBreadcrumbs,
    fetchMovie,
    fetchMovieManifest,
    fetchSerial,
    fetchSerialManifest,
    fetchSimilar,
    fetchMoments,
    fetchCompilations,
  };
}
