import type { ApiCompilation, Compilation } from '@package/sdk/src/api';
import { CompilationMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export interface FetchCompilationsPayload {
  page: number;
  perPage: number;
}

export function useCompilationApi() {
  const { $http } = useNuxtApp();

  const fetchCompilations = (idOrSlug: string): Promise<Compilation> => {
    return $http
      .get<ApiCompilation>(ENDPOINTS.COMPILATION_GET_BY_SLUG, {
        params: {
          idOrSlug,
        },
      })
      .then(CompilationMapper.map);
  };

  const fetchContentCompilations = (id: string): Promise<Compilation[]> => {
    return $http
      .get<ApiCompilation[]>(ENDPOINTS.CONTENTS_COMPILATIONS, { params: { id } })
      .then(CompilationMapper.mapMany);
  };

  const fetchAllCompilations = async (payload: FetchCompilationsPayload) => {
    const query = {
      page: payload.page,
      per_page: payload.perPage,
    };

    const { data, headers } = await $http.sendRawRequest<{ data: ApiCompilation[]; headers: Record<string, any> }>(
      'GET',
      ENDPOINTS.COMPILATIONS,
      undefined,
      {
        query,
      },
    );

    return {
      contents: (data || []).map(CompilationMapper.map),
      totalCount: Number(headers['total-count'] || 0),
    };
  };

  return { fetchCompilations, fetchContentCompilations, fetchAllCompilations };
}
