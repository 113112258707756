import type { ApiReferralProgram, ReferralProgram } from '@package/sdk/src/api';
import { ReferralProgramMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useReferralApi() {
  const { $http } = useNuxtApp();

  const getReferralPrograms = (): Promise<ReferralProgram[]> => {
    return $http.get<ApiReferralProgram[]>(ENDPOINTS.BONUS_PROGRAMS_REFERRAL).then(ReferralProgramMapper.mapMany);
  };

  return { getReferralPrograms };
}
