import { ENDPOINTS } from '@/platform/http';

export function useCpaApi() {
  const { $http } = useNuxtApp();

  const sendCPA = (admitadId: string, createdAt: string) => {
    return $http.post<void>(ENDPOINTS.CPA, {
      admitad_uid: admitadId,
      created_at: createdAt,
    });
  };

  return { sendCPA };
}
