import { AuthInterceptor } from '@/platform/http/auth-interceptor';
import { ErrorInterceptor } from '@/platform/http/error-interceptor';
import { HttpClient } from '@/platform/http/http-client';

import useEnvironment from '../platform/environment/use-environment';

declare module 'nuxt/app' {
  interface NuxtApp {
    $http: HttpClient;
    $flagsHttp: HttpClient;
  }
}

interface InitHttpClientOptions {
  api: string;
  flags: string;
}

const initHttpClients = (options: InitHttpClientOptions) => {
  const app = useNuxtApp();
  const { api, flags } = options;

  const httpClient = new HttpClient(app, api, [new ErrorInterceptor(app), new AuthInterceptor(app)]);
  const flagsHttpClient = new HttpClient(app, flags, [new ErrorInterceptor(app), new AuthInterceptor(app)]);

  return [httpClient, flagsHttpClient];
};

export default defineNuxtPlugin(({ provide }) => {
  const { normalizedApiFlagsBaseUrl, normalizedApiBaseUrl } = useEnvironment();

  const [httpClient, flagsHttpClient] = initHttpClients({
    api: normalizedApiBaseUrl.value,
    flags: normalizedApiFlagsBaseUrl.value,
  });

  provide('http', httpClient);
  provide('flagsHttp', flagsHttpClient);
});
