import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface AppConfig {
  clientCacheVersion: number;
}

export const useSystemStore = defineStore('system-store', () => {
  const isVpnDetected = ref(false);
  const config = ref<AppConfig>({
    clientCacheVersion: 0,
  });

  return {
    isVpnDetected,
    config,
  };
});
