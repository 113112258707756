<template>
  <div ref="headerEl" :class="$style.container" @click="onClick">
    <div :class="$style.logoWrapper">
      <app-header-logo />
      <template v-if="isVpnDetected">
        <icon-vpn :class="$style.icon" @mouseover="onVpnIconMouseover" @mouseleave="onVpnIconMouseleave" />
        <transition name="fade">
          <app-label
            v-if="isVpnLabelShown"
            arrow-position="up"
            :arrow-left="30"
            :class="$style.label"
            position="fixed"
            :top="labelPosition.top"
            :left="labelPosition.left"
          >
            {{ $t('header.enabledVpn') }}
          </app-label>
        </transition>
      </template>
    </div>

    <div v-show="isHeaderShown" ref="navContainerEl" :class="$style.navContainer">
      <div :class="$style.navWrapper">
        <nav
          ref="navEl"
          :aria-label="$t('header.nav.mainMenu')"
          itemtype="https://www.schema.org/SiteNavigationElement"
          itemscope
          :class="$style.nav"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'navBlock')"
        >
          <ul ref="navListEl" role="list" :class="$style.navList">
            <li v-for="link in navItemsFiltered" :key="link?.name">
              <app-link
                v-if="link"
                :to="link.target"
                :aria-label="link.ariaLabel"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'navBlockLink')"
                :class="{
                  [$style.link]: true,
                  [$style.linkHover]: isLinkHovered(link),
                  [$style.linkActive]: isLinkActive(link),
                  [$style.linkWithoutPointerEvents]: isMyChannelLinkClicked,
                }"
                :data-link-type="link.name"
                variation="default"
                tabindex="0"
                @click="onLinkClick(link)"
                @focus="onFocus(link)"
                @blur="onBlur(link)"
                @mouseenter="onMouseenter(link)"
                @mouseleave="onMouseLeave"
              >
                <template v-if="link.name === AppRoute.MyChannel">
                  <icon-my-channel size="small" :is-animated="isIconAnimated" :class="$style.iconKinom" />
                  <transition name="fade">
                    <app-header-my-channel-onboarding-popup
                      v-if="myChannelPopupState[LocalStorageKey.MyChannelOnboardingPopupShown]"
                    />
                  </transition>
                </template>
                <span>{{ link.description }}</span>
              </app-link>
            </li>
          </ul>
        </nav>
        <app-header-links-dropdown-menu
          v-if="isDropdownMenuShown"
          :links="navItemsHidden"
          :current-route="route"
          @click="onAppHeaderLinkClick"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
    </div>
    <transition name="fade">
      <desktop-app-header-catalog-menu
        v-show="isCatalogMovieMenuShown && !isTouchDevice"
        :ref="(comp) => (catalogMovieMenuEl = comp?.$el)"
        :class="$style.catalogMovieMenuDesktop"
        :current-content-route="$AppRoute.Movies"
      />
    </transition>
    <transition name="fade">
      <desktop-app-header-catalog-menu
        v-show="isCatalogSerialMenuShown && !isTouchDevice"
        :ref="(comp) => (catalogSerialMenuEl = comp?.$el)"
        :class="$style.catalogSerialsMenuDesktop"
        :current-content-route="$AppRoute.Serials"
      />
    </transition>
    <section
      v-show="isHeaderShown"
      :class="{
        [$style.search]: true,
        [$style.searchOpened]: isSearchOpen,
        [$style.searchWithLargeMargin]: !isShownPromocodeButton && !isAuth,
      }"
    >
      <content-search ref="searchEl" />
    </section>
    <app-header-promocode-button
      v-if="isShownPromocodeButton"
      :class="{
        [$style.promocodeButton]: true,
        [$style.promocodeButtonWithSmallMargin]: isHeaderLanguagePickerShown || isAuth,
        [$style.promocodeButtonHidden]: isSearchOpen,
      }"
      :text="promocodeButtonText"
      :is-gift-icon-shown="true"
    />
    <nav v-if="isHeaderShown" :class="$style.userNav">
      <app-header-language-block v-if="isHeaderLanguagePickerShown" :app-language="appLanguage" />
      <app-header-user-block :class="{ [$style.userBlock]: !isAuth }" :is-auth="isAuth" />
    </nav>
    <app-burger-menu-button v-show="isHeaderShown" :class="$style.burger" state="closed" @click="toggleBurgerMenu()" />
  </div>
  <div>
    <transition name="fade">
      <div v-show="isMenuOpen || isLanguageMenuOpen" :class="$style.menuMobileWrapper"></div>
    </transition>
    <app-header-mobile-menu
      ref="mobileMenu"
      :is-menu-open="isMenuOpen"
      @toggle-burger-menu="toggleBurgerMenu()"
      @show-language-menu="showLanguageMenu()"
    />
    <app-header-mobile-language
      v-if="isLanguageMenuOpen"
      ref="mobileLanguageMenu"
      @close-language-menu="closeLanguageMenu()"
    />
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { useMyCollectionPageAnalytics } from '@package/sdk/src/analytics';
import { DisposableStore, timeout, toDisposable } from '@package/sdk/src/core';
import { onClickOutside, useMouseInElement, useResizeObserver, useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { type ComponentPublicInstance, computed, onMounted, ref, watch } from 'vue';

import { usePromocodeActivationButton } from '@/code/promocode/use-promocode-activation-button';
import { isSafari, isTouchDevice } from '@/platform/base/platform';
import { AppRoute } from '@/platform/router/routes';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useSessionStore } from '@/stores/use-session-store';
import { useSystemStore } from '@/stores/use-system-store';

import getKmzaPageValue from '../../code/kmza/get-kmza-page-value';
import useMyChannelOnboarding from '../../code/my-channel/use-my-channel-onboarding';
import useScreenOrientation from '../../platform/layout/use-screen-orientation';
import useHostVariation from '../../platform/variation/use-host-variation';
import ContentSearch from '../ContentSearch.vue';
import IconMyChannel from '../icons/IconMyChannel.vue';
import IconVpn from '../icons/IconVpn.vue';
import AppHeaderMyChannelOnboardingPopup from '../popups/AppHeaderMyChannelOnboardingPopup.vue';
import AppLabel from '../ui/AppLabel.vue';
import AppLink from '../ui/AppLink.vue';
import AppBurgerMenuButton from './AppBurgerMenuButton.vue';
import AppHeaderLinksDropdownMenu from './AppHeaderLinksDropdownMenu.vue';
import AppHeaderLogo from './AppHeaderLogo.vue';
import AppHeaderMobileMenu from './AppHeaderMobileMenu.vue';
import AppHeaderPromocodeButton from './AppHeaderPromocodeButton.vue';
import AppHeaderUserBlock from './AppHeaderUserBlock.vue';
import DesktopAppHeaderCatalogMenu from './DesktopAppHeaderCatalogMenu.vue';
import type { AppHeaderItem } from './use-app-header-variables';
import useAppHeaderVariables from './use-app-header-variables';

const { navItems, onAppHeaderLinkClick, isHeaderShown, isHeaderLanguagePickerShown, isLinkActive } =
  useAppHeaderVariables();
const { isShownPromocodeButton, text: promocodeButtonText } = usePromocodeActivationButton();
const { isAuth } = storeToRefs(useSessionStore());
const { appLanguage } = useHostVariation();
const { myChannelPopupState } = useMyChannelOnboarding();

const { onGotoCollectionsPage } = useMyCollectionPageAnalytics();

const { isVpnDetected } = storeToRefs(useSystemStore());
const { orientation } = useScreenOrientation();
const { width: windowWidth } = useWindowSize();

const route = useRoute();
const router = useRouter();
const isCatalogMovieMenuShown = ref(false);
const isCatalogSerialMenuShown = ref(false);
const isVpnLabelShown = ref(true);

let intervalId: number;
let vpnHoverTimeoutId: number;
let disposables = new DisposableStore();

const prevWidth = ref(0);
const navListEl = ref<HTMLUListElement>();
const navContainerEl = ref<HTMLDivElement>();
const navEl = ref<HTMLDivElement>();
const headerEl = ref<HTMLDivElement>();

const searchEl = ref<ComponentPublicInstance<{ isSearchOpen: boolean }>>();
const isSearchOpen = computed(() => searchEl.value?.isSearchOpen);

const isMyChannelLinkClicked = ref(false);

const navItemsFiltered = ref<AppHeaderItem[]>([...navItems]);
const navItemsWidths = ref<number[]>([]);

const isMenuOpen = ref(false);
const toggleBurgerMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const mobileMenu = ref<HTMLElement>();
const mobileLanguageMenu = ref<HTMLElement>();

onClickOutside(mobileMenu, () => {
  isMenuOpen.value = false;
});

onClickOutside(mobileLanguageMenu, () => {
  isLanguageMenuOpen.value = false;
  isMenuOpen.value = false;
});

const catalogMovieMenuEl = ref<HTMLElement>();
const catalogSerialMenuEl = ref<HTMLElement>();
const hoverRoutes = [AppRoute.Movies, AppRoute.Serials];
const myChannelRoutes = [AppRoute.MyChannel, AppRoute.MyChannelMoment];

const isLanguageMenuOpen = ref(false);

const showLanguageMenu = () => {
  isLanguageMenuOpen.value = true;
};

const closeLanguageMenu = () => {
  isLanguageMenuOpen.value = false;
  isMenuOpen.value = true;
};

const onLinkClick = (link: AppHeaderItem) => {
  if (myChannelRoutes.includes(link.name)) {
    isMyChannelLinkClicked.value = true;

    if (intervalId) {
      window.clearTimeout(intervalId);
    }

    intervalId = window.setTimeout(() => {
      isMyChannelLinkClicked.value = false;
    }, 500);
  }

  if (link.name === AppRoute.MyCollectionKinom) {
    onGotoCollectionsPage(getKmzaPageValue(route.name as AppRoute));
  }

  onAppHeaderLinkClick(link);
};

const isOutsideMovieMenu = useMouseInElement(catalogMovieMenuEl).isOutside;
const isOutsideSerialsMenu = useMouseInElement(catalogSerialMenuEl).isOutside;

const labelPosition = computed(() => {
  return windowWidth.value <= ConstantsConfigInstanceWeb.getProperty('mobileLayoutBreakpointPx')
    ? { top: 50, left: 60 }
    : { top: 58, left: 88 };
});

const navItemsHidden = computed(() => navItems.filter((item) => !navItemsFiltered.value.includes(item)));

const isDropdownMenuShown = computed(() => {
  return navItemsHidden.value.length;
});

watch(isOutsideMovieMenu, (val) => {
  isCatalogMovieMenuShown.value = !val;
});

watch(isOutsideSerialsMenu, (val) => {
  isCatalogSerialMenuShown.value = !val;
});

watch(router.currentRoute, (route) => {
  if (route.name === AppRoute.MyChannelMoment) {
    isMyChannelLinkClicked.value = false;
  }
  isVpnLabelShown.value = false;
});

const removeLastNavItem = () => {
  if (
    !headerEl.value ||
    !navEl.value ||
    windowWidth.value <= ConstantsConfigInstanceWeb.getProperty('mobileLayoutBreakpointPx')
  ) {
    return;
  }

  const isNavOverflowed = navEl.value.scrollWidth > navEl.value.clientWidth;

  if (isNavOverflowed && navItemsFiltered.value.length !== 1) {
    navItemsFiltered.value.pop();
  }
};

const isLinkHovered = (link: AppHeaderItem) => {
  return (
    (link.name === AppRoute.Movies && isCatalogMovieMenuShown.value) ||
    (link.name === AppRoute.Serials && isCatalogSerialMenuShown.value)
  );
};

const onFocus = (link: AppHeaderItem) => {
  if (link.name === AppRoute.Movies && !navItemsHidden.value.includes(link)) {
    isCatalogMovieMenuShown.value = true;
  }
  if (link.name === AppRoute.Serials && !navItemsHidden.value.includes(link)) {
    isCatalogSerialMenuShown.value = true;
  }
};

const onBlur = async (link: AppHeaderItem) => {
  if (hoverRoutes.includes(link.name)) {
    await timeout(200);
    isCatalogMovieMenuShown.value = false;
    isCatalogSerialMenuShown.value = false;
  }
};

const onClick = () => {
  isCatalogMovieMenuShown.value = false;
  isCatalogSerialMenuShown.value = false;
};

const isIconAnimated = ref(false);

const onMouseenter = (link: AppHeaderItem) => {
  if (myChannelRoutes.includes(route.name as AppRoute)) {
    return;
  }

  if (link.name === AppRoute.MyChannel) {
    isIconAnimated.value = true;
  }

  if (link.name === AppRoute.Movies) {
    isCatalogMovieMenuShown.value = true;
    isCatalogSerialMenuShown.value = false;
  }

  if (link.name === AppRoute.Serials) {
    isCatalogSerialMenuShown.value = true;
    isCatalogMovieMenuShown.value = false;
  }
};

const clearVpnHoverTimeoutId = () => {
  if (vpnHoverTimeoutId) {
    window.clearTimeout(vpnHoverTimeoutId);
  }
};

const onVpnIconMouseover = () => {
  isVpnLabelShown.value = true;
  clearVpnHoverTimeoutId();
};

const onVpnIconMouseleave = () => {
  isVpnLabelShown.value = false;
};

const onMouseLeave = () => {
  isCatalogMovieMenuShown.value = false;
  isCatalogSerialMenuShown.value = false;
  isIconAnimated.value = false;
};

const removeLastNavItemInLoop = async () => {
  for (let i = 0; i < navItemsWidths.value.length; i++) {
    removeLastNavItem();
    await nextTick();
  }
};

const calculateRemoveOrAddLinkOnResize = async () => {
  if (!navListEl.value || !navContainerEl.value) {
    return;
  }

  const navWrapperWidth = navContainerEl.value.clientWidth;
  // 40 гэп слева и справа, 32 иконка, 30 расстояние слева,
  const margins = 40 + 32 + 30;
  const sufficientWidth = navListEl.value.scrollWidth + navItemsWidths.value[navItemsFiltered.value.length] + margins;
  const isNotAllNavItemsDisplayed = navItemsFiltered.value.length !== navItems.length;

  if (isNotAllNavItemsDisplayed && navWrapperWidth > sufficientWidth && prevWidth.value < navWrapperWidth) {
    navItemsFiltered.value.push(navItems[navItemsFiltered.value.length]);
  } else {
    await removeLastNavItemInLoop();
  }

  prevWidth.value = navWrapperWidth;
};

const calculateRemoveOrAddLinkOnResizeInLoop = async () => {
  if (!isSafari) {
    // в хроме не успевает отрисоваться навигация, поэтому чтобы корректно убрать элементы, которые не влазят, делаем таймаут
    await timeout(300);
  }

  for (let i = 0; i < navItemsWidths.value.length; i++) {
    await calculateRemoveOrAddLinkOnResize();
  }
};

const addResizeListeners = () => {
  disposables = new DisposableStore();

  const searchResizeObserver = useResizeObserver(searchEl, removeLastNavItem);
  disposables.add(
    toDisposable(() => {
      searchResizeObserver.stop();
    }),
  );

  const headerResizeObserver = useResizeObserver(headerEl, removeLastNavItem);

  disposables.add(
    toDisposable(() => {
      headerResizeObserver.stop();
    }),
  );

  const navWrapperResizeObserver = useResizeObserver(navContainerEl, calculateRemoveOrAddLinkOnResize);

  disposables.add(
    toDisposable(() => {
      navWrapperResizeObserver.stop();
    }),
  );
};

watch(orientation, async (val) => {
  if (val === 'horizontal') {
    await calculateRemoveOrAddLinkOnResizeInLoop();
  }
});

onMounted(() => {
  vpnHoverTimeoutId = window.setTimeout(() => {
    if (isVpnLabelShown.value) {
      isVpnLabelShown.value = false;
    }
  }, ConstantsConfigInstanceWeb.getProperty('vpnLabelHideTimeoutMs'));
  if (navListEl.value) {
    const navLinksArray = Array.from(navListEl.value?.children);
    navItemsWidths.value = navLinksArray.map((el) => el.clientWidth);
  }

  addResizeListeners();
});

onBeforeUnmount(clearVpnHoverTimeoutId);
</script>

<style lang="scss" module src="./AppHeader.scss"></style>
