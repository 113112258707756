import type {
  RedirectRequiredEvent,
  VideoPlayerExternalEvent,
} from '@package/media-player/src/player/modules/event/external-event';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import type { Channel } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, watch } from 'vue';

import { useLogger } from '@/platform/logger/use-logger';
import { AppRoute } from '@/platform/router/routes';
import { usePromocodeStore } from '@/stores/use-promocode-store';

import useAuthActions from '../auth/use-auth-actions';
import getKmzaPageValue from '../kmza/get-kmza-page-value';
import useTvPromoPopupActions from '../tv/use-tv-promo-popup-actions';
import usePlayerSentryError from './use-player-sentry-error';

interface IPlayerEventHooksOptions {
  channel?: Channel;
  player: VijuPlayer;
}

export default function usePlayerEventHooks(options: IPlayerEventHooksOptions) {
  const router = useRouter();
  const route = useRoute();
  const logger = useLogger();
  const { promocodeCheckInfo } = storeToRefs(usePromocodeStore());
  const { player } = options;

  const { doRedirectOfferPage, sendToRegistrationPage } = useAuthActions();
  const { startRecordingPlayerExceptions } = usePlayerSentryError();
  const { getTvPromoRedirectFlag, addTvPromoPopupFlag, isPromoPopupShown } = useTvPromoPopupActions();

  const enrichPlayerVideoConfig = () => {
    player.setConfigProperty('content.subscribePromoTexts', {
      subscribeButtonText: promocodeCheckInfo.value?.promoOffer?.subscribeButtonText,
      subscribeButtonSubtext: promocodeCheckInfo.value?.promoOffer?.subscribeButtonSubtext,
    });
  };

  const onRedirectRequired = (event: VideoPlayerExternalEvent<RedirectRequiredEvent>) => {
    // @ts-expect-error
    const { page, isTvPromoPopupShown } = event.data;

    switch (page) {
      case 'my-collection':
        return router.push({ name: AppRoute.MyCollection });
      case 'registration':
        if (isTvPromoPopupShown) {
          addTvPromoPopupFlag(route.path);
        }
        return sendToRegistrationPage();
      case 'offers': {
        const { channel } = options;

        // Если по подписке канал, то отправляем в офферы
        if (channel?.accessKind === 'subscription') {
          return doRedirectOfferPage();
        }

        // Какого-то фига, если юзер зашел по промо акции ТВ попапа, то мы ему должны отобразить ТВ ПОПАП
        // Это ломает всю логику нашу, но что-же поделать.
        const tvPromoPopupRedirectFlag = getTvPromoRedirectFlag();

        if (!isPromoPopupShown() && tvPromoPopupRedirectFlag) {
          return sendToRegistrationPage();
        }

        return doRedirectOfferPage();
      }
      default:
        logger.warn(`VijuVodPlayer#Unexpected redirect value - ${page}`);
    }
  };

  watch(promocodeCheckInfo, enrichPlayerVideoConfig, { deep: true, immediate: true });

  onBeforeUnmount(() => player.endMediaSession());

  onMounted(() => {
    startRecordingPlayerExceptions(player);

    player.setConfigProperty('analytic.metadata', {
      page: getKmzaPageValue(router.currentRoute.value.name as AppRoute),
    });
  });

  return {
    doRedirectOfferPage,
    onRedirectRequired,
  };
}
