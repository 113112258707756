import type { Profile } from '@package/sdk/src/api';
import { ProfileType } from '@package/sdk/src/api';
import type { AnyFn } from '@vueuse/core';

import { parentalProtectedRoutes } from '@/middleware/protecting-parental.global';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

export enum BroadcastChannelEvent {
  Auth = 'auth',
  Logout = 'logout',
  ParentalControl = 'parental-control',
  DeleteAccount = 'delete-account',
  SubscriptionSuccess = 'subscription-success',
}

export interface BroadcastChannelEventPayload {
  [BroadcastChannelEvent.Auth]: undefined;
  [BroadcastChannelEvent.ParentalControl]: Profile;
  [BroadcastChannelEvent.Logout]: undefined;
  [BroadcastChannelEvent.DeleteAccount]: undefined;
  [BroadcastChannelEvent.SubscriptionSuccess]: undefined;
}

export interface BroadcastChannelPayload<E extends BroadcastChannelEvent> {
  event: E;
  payload?: BroadcastChannelEventPayload[E];
}

class AppBroadcastChannel {
  broadcastChannel: BroadcastChannel;

  constructor(name: string) {
    if (!window.BroadcastChannel) {
      this.broadcastChannel = {
        postMessage(_: any) {},
        onmessage(_: any) {},
      } as BroadcastChannel;
    } else {
      this.broadcastChannel = new window.BroadcastChannel(name);
    }
  }

  public postMessage(message: string) {
    this.broadcastChannel.postMessage(message);
  }

  public onMessage(listener: AnyFn) {
    this.broadcastChannel.onmessage = listener;
  }
}

export default defineNuxtPlugin(({ provide }) => {
  const broadcastChannel = new AppBroadcastChannel('viju-broadcast-channel');
  const sessionStore = useSessionStore();
  const layoutStore = useLayoutStore();
  const route = useRoute();
  const router = useRouter();

  const authHandler = () => {
    window.document.addEventListener('visibilitychange', () => {
      window.location.reload();
    });
  };

  const parentalControlHandler = async (profile: Profile) => {
    if (profile.kind === ProfileType.MAIN) {
      await sessionStore.setParentalControlStatus({ isChildrenAccess: true });
    }

    await sessionStore.setProfile(profile);

    // если находится на странице лк, которая недоступна в детском профиле, редикертим на профили
    if (profile.kind === ProfileType.CHILD && parentalProtectedRoutes.has(route.name)) {
      router.push({ name: AppRoute.AccountProfiles });
    }

    layoutStore.forceReRenderPage();

    layoutStore.setCurrentModalName(null);
  };

  const logoutHandler = () => {
    sessionStore.clearSession();
    layoutStore.setCurrentModalName(null);
  };

  const deleteAccountHandler = () => {
    sessionStore.clearSession();
    navigateTo({
      name: AppRoute.Index,
      query: route.query,
    });
  };

  const subscriptionHandler = async () => {
    layoutStore.setCurrentModalName(null);
    await sessionStore.updateCurrentUser();

    navigateTo({
      name: AppRoute.Index,
      query: route.query,
    });
  };

  const getBroadcastChannelHandler = (eventType: BroadcastChannelEvent) => {
    const eventHandlersMap: Record<BroadcastChannelEvent, AnyFn> = {
      [BroadcastChannelEvent.Auth]: authHandler,
      [BroadcastChannelEvent.ParentalControl]: parentalControlHandler,
      [BroadcastChannelEvent.Logout]: logoutHandler,
      [BroadcastChannelEvent.DeleteAccount]: deleteAccountHandler,
      [BroadcastChannelEvent.SubscriptionSuccess]: subscriptionHandler,
    };

    return eventHandlersMap[eventType];
  };

  const listener = async (event: MessageEvent) => {
    const handler = getBroadcastChannelHandler(event.data.event);

    await handler(event.data.payload);
  };
  broadcastChannel.onMessage(listener);

  provide('broadcastChannel', broadcastChannel);
});
