import type {
  ApiFilteredMedia,
  ApiSitemapPage,
  ApiSitemaps,
  FilteredMedia,
  IApiCatalogSeoData,
  MediaContentType,
  Sitemaps,
} from '@package/sdk/src/api';
import { FilteredMediaMapper, SitemapPageMapper, SitemapsMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

import { RequestId } from '../requestId';

export interface IFetchCatalogSeoData {
  contentType: MediaContentType;
  query: Record<string, any>;
}

export function useSeoApi() {
  const { $http } = useNuxtApp();

  const fetchCatalogSeoData = (options: IFetchCatalogSeoData) => {
    const { contentType, query } = options;

    return $http.get<IApiCatalogSeoData>(ENDPOINTS.CONTENTS_SEO_DATA, {
      query: {
        ...query,
        content_type: contentType,
      },
      requestId: RequestId.ContentSeoTextData,
    });
  };

  const fetchSitemapByType = (type: string, page?: number, perPage?: number) => {
    return $http
      .get<ApiSitemapPage>(ENDPOINTS.SITEMAP, {
        query: { type, page, per_page: perPage },
      })
      .then(SitemapPageMapper.map);
  };

  const fetchSitemaps = (): Promise<Sitemaps> => {
    return $http.get<ApiSitemaps>(ENDPOINTS.SITEMAPS).then(SitemapsMapper.map);
  };

  const getFilteredContent = async (filterText: string, page?: number, perPage?: number): Promise<FilteredMedia> => {
    const query = { filters_params: filterText, page, per_page: perPage };
    return await $http.get<ApiFilteredMedia>(ENDPOINTS.CONTENTS_SEO_FILTER, { query }).then(FilteredMediaMapper.map);
  };

  return { fetchSitemaps, fetchSitemapByType, getFilteredContent, fetchCatalogSeoData };
}
