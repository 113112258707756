import type { CollectionContentType, CollectionItem } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';

import { useSessionStore } from '@/stores/use-session-store';

import type { CollectionContext } from './collections';
import { useLocalCollections } from './use-local-collections';
import { useRemoteCollections } from './use-remote-collections';

export function useCollections() {
  const { context: remoteCollectionsContext } = useRemoteCollections();
  const { uploadLocalCollections, context: localCollectionsContext } = useLocalCollections();
  const { isAuth } = storeToRefs(useSessionStore());
  const app = useNuxtApp();

  const collectionContext: CollectionContext = isAuth.value ? remoteCollectionsContext : localCollectionsContext;

  const getCollection = (contentType: CollectionContentType, page: number, limit: number) => {
    return collectionContext.get(contentType, page, limit);
  };

  const addToCollection = (item: CollectionItem) => {
    app.$vkEventSender.addToCart(item.id);
    app.$http.cache.clear();

    return collectionContext.add([item]);
  };

  const removeFromCollection = (id: string) => {
    app.$http.cache.clear();

    return collectionContext.remove(id);
  };

  return {
    getCollection,
    addToCollection,
    removeFromCollection,
    uploadLocalCollections,
  };
}
