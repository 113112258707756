import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import type { LocationQuery } from 'vue-router';

import { ApiError } from '@/platform/http/errors';
import { useLogger } from '@/platform/logger/use-logger';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';
import { useTvCodeConnectionStore } from '@/stores/use-tv-code-connection-store';

import useAuthActions from '../auth/use-auth-actions';
import { useSessionsApi } from '../user/use-sessions-api';
import { useUsersApi } from '../user/use-users-api';

export default function useTvCodeConnection() {
  const logger = useLogger();
  const route = useRoute();
  const sessionsApi = useSessionsApi();
  const userApi = useUsersApi();

  const sessionStore = useSessionStore();
  const { parseBackRoutePathAndQuery } = useAuthActions();

  const { isAuth, savedSmartTvCode } = storeToRefs(sessionStore);
  const {
    tvCode,
    codeErrorText,
    countDown,
    deviceSessions,
    isSuccessCode,
    isConnectRequestLoading,
    isTvConnectNotificationShown,
  } = storeToRefs(useTvCodeConnectionStore());

  let sessionRequestTimeoutId = 0;

  const isPinCodeQueryExists = computed(() => Boolean(savedSmartTvCode.value));
  const isCodeFull = computed(() => tvCode.value.length >= ConstantsConfigInstanceWeb.getProperty('tvPinCodeLength'));
  const isConnectBtnDisabled = computed(() => !isCodeFull.value || Boolean(codeErrorText.value) || isSuccessCode.value);
  const isTvCodePage = computed(() => route.name === AppRoute.TVCodePage || route.name === AppRoute.GoTVCodePage);
  const tvCodeBackUrl = computed(() => {
    const backUrl = route.query[RouteQuery.BackRoute] || '/';
    const parsedBackUrl = parseBackRoutePathAndQuery(backUrl);

    if (!parsedBackUrl.query[RouteQuery.TvSmartCode]) {
      return undefined;
    }

    return parsedBackUrl;
  });

  const clearTvCodeQuery = (query: LocationQuery) => {
    const _query = { ...query };
    Reflect.deleteProperty(_query, RouteQuery.TvSmartCode);
    navigateTo({ query: _query }, { replace: true });
  };

  const updateSessions = () => sessionsApi.getSessions(true).catch(() => []);

  const updateCode = (value: string) => {
    tvCode.value = value;

    isSuccessCode.value = false;
    codeErrorText.value = '';
  };

  const countDownTimer = async (resetInnerModel?: VoidFunction) => {
    if (countDown.value > 0) {
      if (sessionRequestTimeoutId) {
        window.clearTimeout(sessionRequestTimeoutId);
      }

      sessionRequestTimeoutId = window.setTimeout(() => {
        countDown.value -= 1;
        countDownTimer(resetInnerModel);
      }, 1000);
    } else {
      deviceSessions.value = await updateSessions();
      isSuccessCode.value = false;
      isTvConnectNotificationShown.value = false;
      codeErrorText.value = '';

      if (resetInnerModel) {
        resetInnerModel();
      }
      tvCode.value = '';

      if (sessionRequestTimeoutId) {
        window.clearTimeout(sessionRequestTimeoutId);
      }
    }
  };

  const connect = async (resetInnerModel?: VoidFunction) => {
    await doConnect(tvCode.value, resetInnerModel);
  };

  const doConnect = async (code: string, resetInnerModel?: VoidFunction) => {
    if (!isAuth.value && isTvCodePage.value) {
      return navigateTo({
        name: AppRoute.AuthEnter,
        query: {
          [RouteQuery.BackRoute]: route.fullPath,
        },
      });
    }

    isConnectRequestLoading.value = true;

    try {
      await userApi.confirmTvToken(code);

      countDown.value = 10;

      isSuccessCode.value = true;
      codeErrorText.value = '';
      isTvConnectNotificationShown.value = true;

      await countDownTimer(resetInnerModel);

      if (isTvCodePage.value) {
        await navigateTo({ name: AppRoute.Index });
      }

      if (tvCodeBackUrl.value) {
        clearTvCodeQuery(tvCodeBackUrl.value.query);
      }
    } catch (error) {
      isSuccessCode.value = false;

      if (error instanceof ApiError) {
        codeErrorText.value = error.message;

        if (resetInnerModel) {
          resetInnerModel();
        }
        tvCode.value = '';

        clearTvCodeQuery(route.query);
      }

      logger.error(error);
    } finally {
      isConnectRequestLoading.value = false;
      sessionStore.setSavedSmartTvCode('');
    }
  };

  const handleTvCodeQuery = async (resetInnerModel?: VoidFunction) => {
    const savedTvCode = savedSmartTvCode.value;

    if (!savedTvCode || savedTvCode.length !== ConstantsConfigInstanceWeb.getProperty('tvPinCodeLength')) {
      return;
    }

    tvCode.value = savedTvCode;
    await nextTick();
    await doConnect(savedTvCode, resetInnerModel);
  };

  return {
    isTvCodePage,
    isPinCodeQueryExists,
    isConnectBtnDisabled,
    updateSessions,
    updateCode,
    countDownTimer,
    connect,
    handleTvCodeQuery,
  };
}
