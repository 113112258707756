import type { ApiCollectionItem, CollectionContentType, CollectionItem, CollectionType } from '@package/sdk/src/api';
import { CollectionItemMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export function useUserCollectionApi() {
  const { $http } = useNuxtApp();

  const getItems = (contentType: CollectionContentType, page: number, limit: number): Promise<CollectionItem[]> => {
    return $http
      .get<ApiCollectionItem[]>(ENDPOINTS.COLLECTION_ITEMS, {
        query: { content_type: contentType, page, per_page: limit },
      })
      .then(CollectionItemMapper.mapMany);
  };

  const deleteItem = (id: string): Promise<void> => {
    return $http.delete<void>(ENDPOINTS.COLLECTION_ITEMS, { query: { content_id: id } });
  };

  const addItems = (ids: string[] | string, collectionType?: CollectionType): Promise<CollectionItem[]> => {
    return $http
      .post<ApiCollectionItem[]>(ENDPOINTS.COLLECTION_ITEMS, {
        content_ids: typeof ids === 'string' ? [ids] : ids,
        collection_type: collectionType,
      })
      .then(CollectionItemMapper.mapMany);
  };

  return { getItems, deleteItem, addItems };
}
