import { useLogger } from '@/platform/logger/use-logger';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLocalStorage } from '@/platform/storage/use-local-storage';

import { useCpaApi } from '../cpa/use-cpa-api';

export function useAdmitadUserId() {
  const { sendCPA } = useCpaApi();
  const { getValue, remove } = useLocalStorage();
  const logger = useLogger();

  const sendAdmitadEvent = () => {
    // admitad section
    const admitadId = getValue(LocalStorageKey.AdmitadUid);

    if (!admitadId) {
      return;
    }

    const { value, expires } = admitadId;

    // Какая-то странность случилась, на всякий случай подстрахуемся.
    // Мы ожидаем у этого ключа обязательно наличие expires поля.
    // Если его вдруг там не оказалось - значит ключ не валиден, и работать с ним мы не можем
    // втихую подтираем и делаем вид что ничего не было
    if (!expires) {
      remove(LocalStorageKey.AdmitadUid);
      return;
    }

    // Неблокирующим потоком, так как нам в целом пофиг когда там запрос выполнится
    try {
      sendCPA(value as string, new Date(expires).toUTCString());
    } catch (e) {
      logger.warn(e);
      // Если упадет с ошибкой - не страшно
    }
  };

  return {
    sendAdmitadEvent,
  };
}
