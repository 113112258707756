import { Disposable } from '@package/sdk/src/core';
import { onUnexpectedError } from '@package/sdk/src/core/errors/on-unexpected-error';
import { isSchedulerAPISupported } from '@PLAYER/player/modules/performance/performance';

export type Task = (...args: never[]) => void;

type TaskPriority = 'user-blocking' | 'user-visible' | 'background';

interface PostTaskOptions {
  priority?: TaskPriority;
  delay?: number;
  signal?: AbortSignal;
}

declare global {
  interface Window {
    scheduler: {
      postTask(task: Task, options?: PostTaskOptions): Promise<void>;
    };
  }
}

const scheduler = new (class TaskScheduler extends Disposable {
  private readonly isSupported: boolean;

  constructor() {
    super();

    this.isSupported = isSchedulerAPISupported();
  }

  public async postTask(task: Task, options?: PostTaskOptions): Promise<void> {
    if (!this.isSupported) {
      return Reflect.apply(task, undefined, []);
    }

    try {
      await window.scheduler.postTask(task, options);
    } catch (error) {
      onUnexpectedError(error);
    }
  }
})();

export default scheduler;
