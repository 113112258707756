<template>
  <div :class="$style.logo">
    <app-link
      :to="{ name: $AppRoute.Index }"
      :class="$style.logoLink"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'appLogo')"
      @click="onLogoClick"
    >
      <app-dev-build-badge v-if="!isRelease" :class="$style.logoBadge" :text="appVariation" />
      <icon-logo size="mini" />
    </app-link>
  </div>
</template>

<script lang="ts" setup>
import useEnvironment from '@/platform/environment/use-environment';
import { AppRoute } from '@/platform/router/routes';
import useHostVariation from '@/platform/variation/use-host-variation';

import AppDevBuildBadge from '../AppDevBuildBadge.vue';
import IconLogo from '../icons/IconLogo.vue';
import AppLink from '../ui/AppLink.vue';
import useAppHeaderVariables from './use-app-header-variables';

const { appVariation } = useHostVariation();
const { isRelease } = useEnvironment();

const { onAppHeaderLinkClick, navItems } = useAppHeaderVariables();

const onLogoClick = () => {
  const item = navItems.find((item) => item.name === AppRoute.Index);

  if (item) {
    onAppHeaderLinkClick(item);
  }
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.logo {
  @include fonts.WebLabel-1;
  padding-left: var(--g-spacing-48);
  letter-spacing: 0.2em;
  color: var(--color-text-primary);
}

.logoLink {
  position: relative;
  display: block;
}

.logoBadge {
  position: absolute;
  top: 0;
  line-height: 1;
}

@include breakpoints.max-width-800 {
  .logo {
    margin-right: auto;
    padding-left: var(--g-spacing-20);
  }
}
</style>
