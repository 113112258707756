import { getUtmParams } from '@/code/auth/use-session-actions';

import type { IUserCreateSession } from '../user/use-users-api';
import { OAuthProviderType } from './o-auth';
import type { OAuthResponse } from './types/o-auth';

export function useOAuthResponse() {
  const mapResponseToAuthBody = ({ provider, token, uuid }: OAuthResponse): Partial<IUserCreateSession> => ({
    credentials: {
      type: provider,
      silent_token: provider === OAuthProviderType.VK ? token : undefined,
      oauth_token: provider === OAuthProviderType.Yandex ? token : undefined,
      uuid,
    },
    user_parameters: getUtmParams(),
  });

  return { mapResponseToAuthBody };
}
