export function useKmzaGnezdoEvents() {
  const getGnezdoScript = (trackerId: string) => {
    const commentStart = document.createComment('Gnezdo.ru tracker');
    const firstScript = document.createElement('script');
    const secondScript = document.createElement('script');
    const commentEnd = document.createComment('/Gnezdo.ru tracker');

    firstScript.type = 'text/javascript';
    firstScript.src = '//news.gnezdo.ru/gnezdo_news_tracker_new.js';
    firstScript.async = true;

    secondScript.type = 'text/javascript';
    secondScript.innerHTML = `
    (function(w, d, c, s, t){
    w[c] = w[c] || [];
    w[c].push(function(){
    gnezdo_tracker.create({
    trackerId: ${trackerId}
    });
    });
    })(window, document, 'gnezdoTrackerAsyncCallbacks');`;

    const gnezdoScript = document.createDocumentFragment();

    gnezdoScript.appendChild(commentStart);
    gnezdoScript.appendChild(firstScript);
    gnezdoScript.appendChild(secondScript);
    gnezdoScript.appendChild(commentEnd);

    return gnezdoScript;
  };

  const injectGnezdoPixel = () => {
    const GNEZDO_ID = '3055919953';

    document.body.appendChild(getGnezdoScript(GNEZDO_ID));
  };

  return {
    injectGnezdoPixel,
  };
}
