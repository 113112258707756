import type { ApiGenre } from '@package/sdk/src/api';
import { GenreMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';
import { useLogger } from '@/platform/logger/use-logger';

export function useGenresApi() {
  const { $http } = useNuxtApp();
  const logger = useLogger();

  const fetchGenres = async () => {
    try {
      const data = await $http.get<ApiGenre[]>(ENDPOINTS.GENRES).then(GenreMapper.mapMany);
      return data;
    } catch (e) {
      logger.error(e);
      return [];
    }
  };

  return { fetchGenres };
}
