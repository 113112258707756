import { BonusProgram } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

import useLocale from '../../platform/localization/use-locale';
import useHostVariation from '../../platform/variation/use-host-variation';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '../kmza/use-driver-kmza';

const availableRoutes = [
  AppRoute.Index,
  AppRoute.Movies,
  AppRoute.MovieCard,
  AppRoute.Serials,
  AppRoute.SerialCard,
  AppRoute.SerialEpisodeCard,
  AppRoute.SerialSeasonCard,
  AppRoute.TV,
  AppRoute.TVChannel,
  AppRoute.MyCollection,
  AppRoute.Playlists,
  AppRoute.MyChannel,
  AppRoute.MyChannelMoment,
  AppRoute.PlaylistItem,
  AppRoute.Search,
  AppRoute.Compilations,
  AppRoute.CompilationsItem,
  AppRoute.MyCollectionContent,
  AppRoute.MyCollectionKinom,
  AppRoute.MovieFilter,
  AppRoute.SerialFilter,
];

/**
 *
 * Кнопка активация промокода (отображается в шапке)
 * @returns {{onClick: () => void, text: string, url: string}}
 */
export const usePromocodeActivationButton = () => {
  const { isActiveSubscription, isSubscriptionTrial } = storeToRefs(useOffersStore());
  const { translate } = useLocale();
  const { isPromocodeEnabled } = useHostVariation();

  const currentRoute = useRoute();
  const sessionsStore = useSessionStore();

  const { injectAdriverScript } = useKmzaDriverEvents();
  const { isAuth, isPartnerUser } = storeToRefs(sessionsStore);

  // Активная или триал подписка
  const isActiveOrTrialSubscription = computed(() => isActiveSubscription.value || isSubscriptionTrial.value);
  // Нужно ли отображать вообще эту кнопку
  const isShownPromocodeButton = computed(() => {
    if (!isPromocodeEnabled.value) {
      return false;
    }

    const canShowRoute = availableRoutes.includes(currentRoute.name as AppRoute);

    // Показываем не на всех страницах, а лишь на некоторых
    if (!canShowRoute) {
      return false;
    }

    // Для юзеров-партнеров кнопка не доступна
    if (isPartnerUser.value) {
      return false;
    }

    return !isActiveOrTrialSubscription.value;
  });

  // Текст кнопки (сказали пока захардкодить)
  const text = translate('offer.promocode.activation');

  // Роут, куда будем отправлять при клике
  const route = computed<RouteLocationRaw>(() => {
    switch (true) {
      case !isAuth.value:
        return {
          name: AppRoute.AuthEnter,
          query: {
            [RouteQuery.BackRoute]: currentRoute.fullPath,
            [RouteQuery.FromPromocodeButton]: '1',
          },
        };
      case !isActiveOrTrialSubscription.value:
      default:
        return {
          name: AppRoute.AccountBonuses,
          query: {
            [RouteQuery.BonusProgram]: BonusProgram.PromoCode,
            [RouteQuery.BackRoute]: currentRoute.fullPath,
          },
        };
    }
  });

  const onClick = () => {
    navigateTo(route.value);
    injectAdriverScript(AdriverAnalyticEvent.PromocodeButton);
  };

  return { isShownPromocodeButton, text, onClick };
};
