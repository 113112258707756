import type { ApiMoment, Moment } from '@package/sdk/src/api';
import { MomentMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export interface FetchMomentsPayload {
  seed?: number;
  page?: number;
  perPage: number;
  playlistCode?: string;
  momentId?: string;
}

export function useFeedApi() {
  const { $http } = useNuxtApp();

  const fetchMoments = (payload: FetchMomentsPayload): Promise<Moment[]> => {
    const query = {
      seed: payload.seed,
      page: payload.page,
      per_page: payload.perPage,
      ...(payload.playlistCode && { playlist_code: payload.playlistCode }),
      moment_id: payload.momentId,
    };

    return $http.get<ApiMoment[]>(ENDPOINTS.MOMENTS_FEED, { query }).then(MomentMapper.mapMany);
  };

  return { fetchMoments };
}
